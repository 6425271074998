export default function(){
	return {
		restrict:'E',
		replace:true,
		scope:{
			currentSelectedId:'='
		},
		template:`<select name="parent" id="parent" class="form-control" convert-to-number ng-model="$ctrl.currentSelectedId">
					<option value=""></option>
                    <option ng-repeat="page in $ctrl.pages track by $index" value="{{page.id}}">
                        {{'-'.repeat(page.depth)}}{{page.title}}
                    </option>
                </select>`,
		bindToController:true,
		controllerAs:'$ctrl',
		controller:function($http, PageResource)
		{
			this.pages = [];
			this.currentSelectedId = null;

			this.PageResource = new PageResource();
			this.PageResource.all().then((pageCollection) => this.pages = pageCollection);
		}
	}
}