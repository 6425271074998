class NewsController
{
	constructor(NewsResource){
		this.NewsResource = new NewsResource();
		this.news = [];

		this.NewsResource.all().then((news) => this.news = news);
	}

	getNews()
	{
		return this.news;
	}

	delete(News)
	{
		var id = News.id;
		News.destroy().then(() => {
			this.news = this.news.filter((n) => n.id != id);
		});
	}
}

NewsController.$inject = ['NewsResource'];

export default {
	templateUrl:'/views/news/news.html',
	controller:NewsController
}