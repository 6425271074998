class PageMenuDirectiveController
{
	constructor($state, PageResource, PAGE_MENU_LINKS){
		this.$state = $state;
		this.PageResource = new PageResource();
		this.links = PAGE_MENU_LINKS;
		this.pages = [];
	}

	$onInit(){
		this.sync();
	}

	sync(){
		this.PageResource.all().then((a) => this.pages = a);
	}

	onSearchItemSelected(i){
		this.$state.go('page-edit', {id:i.id});
	}
}
PageMenuDirectiveController.$inject = ['$state', 'PageResource', 'PAGE_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			disableSearchBar:'='
		},
		templateUrl:'/views/page/page-menu.html',
		controller:PageMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}