import MemberComponent from './MemberComponent';
import MembersComponent from './MembersComponent';
import MemberResource from './MemberResource';
import MemberHeadingDirective from './MemberHeadingDirective'
import MemberFormDirective from './MemberFormDirective';
import MemberActivitiesDirective from './MemberActivitiesDirective';
import MemberBillingsDirective from './MemberBillingsDirective';
import MembersFiltersDirective from './MembersFiltersDirective';
import MembersTableDirective from './MembersTableDirective';
import MemberMenuDirective from './MemberMenuDirective';
import MemberRenewDirective from './MemberRenewDirective';

let MemberModule = angular.module('member.module', [])
.constant('MEMBER_MENU_LINKS', [
{
	icon: 'create',
	svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
		<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
	title: 'Ajouter un contact',
	state: {
		name: 'members.create'
	}
}, {
	icon: 'consult',
	svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
	<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
	title: 'Liste des contacts',
	state: {
		name: 'members',

	}
},
	{
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
	<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Liste des contacts désactivés',
		state: {
			name: 'members.deactivited',
			params:{
				isDeactivated:1
			}
		}
	}])
.provider('MemberResource', MemberResource)
.component('member', MemberComponent)
.component('members', MembersComponent)
.directive('memberMenu', MemberMenuDirective)
.directive('membersFilters', MembersFiltersDirective)
.directive('membersTable', MembersTableDirective)
.directive('memberHeading', MemberHeadingDirective)
.directive('memberActivities', MemberActivitiesDirective)
.directive('memberBillings', MemberBillingsDirective)
.directive('memberForm', MemberFormDirective)
.directive('memberRenew', MemberRenewDirective);

export default MemberModule;