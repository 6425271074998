function JoinFilter()
{
	return function(arrayToJoin = [], joinString = {})
	{
		if(!Array.isArray(arrayToJoin))
		{
			throw new TypeError("Expected Array");
		}

		let joinedArrayString = arrayToJoin.reduce((memo, a) => {
			if(a && a != ''){ memo.push(a); }
			return memo;
		}, []).join(joinString);

		return joinedArrayString;
	}
}

export default JoinFilter;