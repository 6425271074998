class NotificationsController {
	constructor(/*socket,*/ SOCKET_EVENTS){
		this.SOCKET_EVENTS = SOCKET_EVENTS;
		//this.socket = socket;
		this.listeners = {};

		this.counters = {
			activities:0,
			members:0
		};

		this.total = Object.keys(this.counters).reduce((m, k) => m + this.counters[k], 0);
	}

	bindSocketEventsListeners(){
		//this.listeners[this.SOCKET_EVENTS.ACTIVITY_CREATED] = this.socket.on(this.SOCKET_EVENTS.ACTIVITY_CREATED, this.onActivityCreatedSocketEvent.bind(this));
	}

	unbindSocketEventsListeners(){
		//this.socket.off(this.SOCKET_EVENTS.ACTIVITY_CREATED, this.listeners[this.SOCKET_EVENTS.ACTIVITY_CREATED]);
	}

	$onInit(){
		//this.bindSocketEventsListeners();
	}

	$onDestroy(){
		//this.unbindSocketEventsListeners();
	}

	onActivityCreatedSocketEvent(){
		this.counters.activities++;
	}
}
NotificationsController.$inject = [/*'socket',*/ 'SOCKET_EVENTS'];
export default function(){
	return {
		restrict:'E',
		bindToController:true,
		controllerAs:'$ctrl',
		controller:NotificationsController,
		templateUrl: '/views/navigation-notifications-bloc.html'
	}
}