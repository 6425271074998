function TimePickerDirective($window){
	return {
		restrict:'A',
		require:'^ngModel',
		link:function(scope, $element, attrs, ngModel)
		{
			var $parent = $element.parent();

			$element.timepicker({
				appendTo:$parent,
				timeFormat:'H:i:s',
				className:'timepicker',
				onSelect:function (date) {
					scope.$apply(function () { ngModel.$setViewValue(date); });
				}
			});

			$element.on('showTimepicker', function(){
				$parent.find('.ui-timepicker-wrapper').perfectScrollbar();
			});

			$element.on('hideTimepicker', function(){
				$parent.find('.ui-timepicker-wrapper').perfectScrollbar("destroy");
			});
		}
	}
}
TimePickerDirective.$inject = ['$window'];

export default TimePickerDirective;