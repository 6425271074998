class PageBodyEditorDirectiveController{
	constructor(){

	}
}

export default function(){
	return {
		restrict:'E',
		scope:{
			body:'=',
			disabled:'='
		},
		templateUrl:'/views/page/page-body-editor.html',
		controller:PageBodyEditorDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}