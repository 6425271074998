class SingleNewsController
{
	constructor($state){
		this.$state = $state;

		this.tinymceOptions = {
			plugins: 'link image code',
			menubar: true,
			toolbar: 'undo redo | link | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
		};

		this.logos = [
			'bleu',
			'gris',
			'jaune',
			'mauve',
			'orange',
			'rose',
			'vert',
			'violet'
		];
	}

	save(News)
	{
		News.save().then(() => this.$state.go('actualites'));
	}

	isSelected(color) {
		return this.news.logo == color;
	}
}

SingleNewsController.$inject = ['$state'];

export default {
	templateUrl:'/views/news/single-news.html',
	controller:SingleNewsController,
	bindings:{
		news:'='
	}
}