class ActivitiesAgendaDirectiveController
{
	constructor($state, $filter)
	{
		this.$state = $state;
		this.$filter = $filter;
		this.activities = $filter('filterArrayByObject')(this.activities, {isIncoming:1, validated:1});
		this.showActivitiesList = false;
		this.showActivitiesCalendar = true;
	}

	onShowLinkClick(a)
	{
		this.$state.go('activities.edit', {id:a.id});
	}

	displayActivitiesList()
	{
		this.showActivitiesList = true;
		this.showActivitiesCalendar = false;
	}

	displayActivitiesCalendar()
	{
		this.showActivitiesList = false;
		this.showActivitiesCalendar = true;
	}

	dateHasEvents(date)
	{
		return (this.activities.filter((a) => a.date == date).length > 0);
	}

	onSelect(date)
	{
		this.$state.go('activities.list', {date:date});
	}
}
ActivitiesAgendaDirectiveController.$inject = ['$state', '$filter'];

export default function(){
	return {
		restrict:'E',
		scope:{
			activities:'='
		},
		templateUrl:'/views/activity/activities-agenda.html',
		controller:ActivitiesAgendaDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true,
		link:function(scope, element, attrs, controller)
		{
			function init()
			{
				var $list = element.find('.activities-list');
				var $calendar = element.find('.activities-calendar');
				/**
				 * Destruction préalable des plugins jQuery
				 */
				$list.perfectScrollbar("destroy");
				$calendar.datepicker("destroy");

				/**
				 * Binding des plugins jQuery
				 */
				$list.perfectScrollbar();
				$calendar.datepicker({
					closeText: "Fermer",
					prevText: "Précédent",
					nextText: "Suivant",
					currentText: "Aujourd'hui",
					monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
					monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
					dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
					dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
					dayNamesMin: [ "D","L","M","M","J","V","S" ],
					weekHeader: "Sem.",
					dateFormat: "yy-mm-dd",
					firstDay: 1,
					isRTL: false,
					showMonthAfterYear: false,
					yearSuffix: "",
					beforeShowDay:function(date){
						let hasEvents = controller.dateHasEvents(moment(date).format('YYYY-MM-DD'));
						let isSelectable = true;
						let className = hasEvents ? 'with-events' : false;
						let tooltip = '';
						return [isSelectable, className, tooltip];
					},
					onSelect:function(date){
						if(controller.onSelect){
							controller.onSelect(moment(date).format('YYYY-MM-DD'));
						}
					}
				});
			}

			scope.$watch(() => controller.activities, function(n){
				if(angular.isDefined(n) && Array.isArray(n) && n.length > 0){ init(); }
			});
		}
	}
};
