import NewsResource from './NewsResource';
import NewsComponent from './NewsComponent';
import SingleNewsComponent from './SingleNewsComponent';
import NewsMenuDirective from './NewsMenuDirective';
import NewsTableDirective from './NewsTableDirective';
import NewsHeadingDirective from './NewsHeadingDirective';
import NewsFormDirective from './NewsFormDirective';

export default angular.module('news.module', [])
.constant('NEWS_MENU_LINKS', [
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
		<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Ajouter une actualité',
		state: {
			name: 'actualites.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
	<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Liste des actualités',
		state: {
			name: 'actualites'
		}
	},
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Créer une page',
		state: {
			name: 'pages.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Consulter les pages',
		state: {
			name: 'pages'
		}
	}
])
.provider('NewsResource', NewsResource)
.component('actualites', NewsComponent)
.component('actualite', SingleNewsComponent)
.directive('actualitesMenu', NewsMenuDirective)
.directive('actualitesTable', NewsTableDirective)
.directive('actualiteHeading', NewsHeadingDirective)
.directive('actualiteForm', NewsFormDirective);