class ApplicationController{
	constructor($state){
		Object.assign(this, {$state});
	}
	closeOverlay(){
		var $overlay = angular.element('.overlay');
		var $body = angular.element('body');

		if ($overlay.hasClass('visible')) {
			$overlay.addClass('masked').removeClass('visible');
		} else {
			$overlay.addClass('visible').removeClass('masked');
		}

		if ($body.hasClass('overlayOn')) {
			$body.addClass('overlayOff').removeClass('overlayOn');
		} else {
			$body.addClass('overlayOn').removeClass('overlayOff');
		}
	}
}
ApplicationController.$inject = ['$state'];

let ApplicationComponent = {
	templateUrl: '/views/application.html',
	controller: ApplicationController
};

export default ApplicationComponent;