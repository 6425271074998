class ActivityController
{
	constructor($state, $filter)
	{
		this.$state = $state;
		this.$filter = $filter;

		this.errors = {
			form:{}
		};

		this.panes = {
			current:'form',
			is:function(name){
				return (this.current === name);
			},
			set:function(name){
				return this.current = name;
			}
		};
	}

	export()
	{
		this.activity.export().then((response) =>
		{
			let time     = new Date().getTime();
			let headers  = response.headers();
			let content  = response.data;
			let filename = `activite_${time}.xls`;
			let blob     = new Blob([content], {type: headers['Content-Type']});

			return saveAs(blob, filename);
		});
	}

	print()
	{
		this.activity.toPDF().then((binary) => {
			let blob, url;

			blob = new Blob([binary], {type:'application/pdf'});

			url = window.URL.createObjectURL(blob);

			let a = document.createElement("a");
			a.style = 'display:none';

			document.body.appendChild(a);

			a.href = url;
			a.download = this.activity.title.toLowerCase() + '.pdf';
			a.click();

			window.URL.revokeObjectURL(url);
		});
	}

	save(){
		return this.activity.id ? this.update() : this.create();
	}

	create(){
		this.activity.save().then(() => {
			this.$state.go('activities.list', {}, {reload: true});
		}, (errors) => {
			this.errors.form = errors;
		});
	}

	update(){
		console.warn('alex - this.districtIds', this.districtIds);
		this.activity.save().then((r) => {
			if (this.$state.current.name === 'activities.validate.edit'){
				this.$state.go('activities.validate',{}, {reload: true});
			}
			else{
				this.$state.go('activities.list', {}, {reload:true});
			}
		}, (errors) => {
			this.errors.form = errors;
			console.log(this.errors);
		});
	}
}
ActivityController.$inject = ['$state', '$filter', 'ActivityResource'];

let ActivityComponent = {
	templateUrl: 'views/activity/activity.html',
	controller: ActivityController,
	bindings:{
		'activity':'='
	}
};

export default ActivityComponent;
