class MemberMenuDirectiveController{
	constructor($state, MEMBER_MENU_LINKS)
	{
		this.$state  = $state;
		this.links   = MEMBER_MENU_LINKS;
	}

	onSearchItemSelected(m){
		this.$state.go('members.edit', {id:m.id});
	}
}
MemberMenuDirectiveController.$inject = ['$state', 'MEMBER_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			members:'=',
			disableSearchBar:'='
		},
		templateUrl:'/views/member/member-menu.html',
		controller:MemberMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}