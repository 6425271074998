class SettingsResource {

    constructor(attributes = {}) {
        this.id = attributes.id || null;
        this.namespace = attributes.namespace;
        this.key = attributes.key;
        this.value = attributes.value;
        this.created_at = attributes.created_at || null;
        this.updated_at = attributes.updated_at || null;
    }

    all() {
        var deferred = this.$q.defer();
        this.$http.get(`${this.REMOTE_BASE_URL}/settings`)
            .success((rows) => deferred.resolve(rows.map((u) => new SettingsResource(u))))
            .error((err) => deferred.reject(err));

        return deferred.promise;
    }

    save() {
        var request, deferred = this.$q.defer();
        if (this.id != null) {
            request = this.$http.put(`${this.REMOTE_BASE_URL}/settings/${this.id}`, this);
        }
        else {
            request = this.$http.post(`${this.REMOTE_BASE_URL}/settings`, this);
        }
        request.success((u) => deferred.resolve(new SettingsResource(u)))
               .error((err) => deferred.reject(err));

        return deferred.promise;
    }

    destroy() {
        var deferred = this.$q.defer();
        this.$http.delete(`${this.REMOTE_BASE_URL}/settings/${this.id}`)
            .success(() => deferred.resolve())
            .error((err) => deferred.reject(err));

        return deferred.promise;
    }
}

export default function() {
    this.$get = [
        '$http',
        '$httpParamSerializer',
        '$q',
        'REMOTE_BASE_URL',
        '$timeout',
        function ($http, $httpParamSerializer, $q, REMOTE_BASE_URL, $timeout) {
            SettingsResource.prototype.$http = $http;
            SettingsResource.prototype.$httpParamSerializer = $httpParamSerializer;
            SettingsResource.prototype.$q = $q;
            SettingsResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
            SettingsResource.prototype.$timeout = $timeout;
            return SettingsResource;
        }
    ];
}