function FilterArrayByObject()
{
	return function(arrayToFilter = [], filterObject = {})
	{
		if(!Array.isArray(arrayToFilter))
		{
			throw new TypeError("Expected Array");
		}

		var filteredArray = arrayToFilter;
		Object.keys(filterObject).forEach((filterKey) =>
		{
			filteredArray = filteredArray.filter((arrayItem) =>
			{
				let keyExists = filterKey in arrayItem;
				if(keyExists)
				{
					let filterValue = filterObject[filterKey];
					let arrayItemValue = arrayItem[filterKey];
					let isArray = Array.isArray(filterValue);
					let filterValueIsArray = Array.isArray(filterValue);
					let arrayItemValueIsArray = Array.isArray(arrayItemValue);
					let isString = (typeof filterValue == 'string');
					let isNumber = (typeof filterValue == 'number');//CGN
					let isBoolean = (typeof filterValue == 'boolean');//CGN
					if (filterValueIsArray && arrayItemValueIsArray && filterKey === "activityDistrictIds"){
						if(filterValue.length < 1)
						{
							return true;
						}
						return filterValue.find(e => arrayItemValue.indexOf(e) !== -1);
					}
					else if(isArray)
					{
						console.log('isArray', isArray);
						if(filterValue.length < 1)
						{
							return true;
						}
						return filterValue.indexOf(arrayItemValue) > -1;
					}else if(isString){
						return String(arrayItemValue).match(new RegExp(filterValue, 'i'));
//CGN+
					}else if(isNumber){
						return filterValue === arrayItemValue;
//CGN-
					}else if(isBoolean){
						return filterValue === arrayItemValue;
					}
				}
				return true;
			});
		});
		return filteredArray;
	}
}

export default FilterArrayByObject;
