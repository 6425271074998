class SettingsMenuDirectiveController{
	constructor($state, SETTINGS_MENU_LINKS){
		this.$state = $state;
		this.links = SETTINGS_MENU_LINKS;
	}
}
SettingsMenuDirectiveController.$inject = ['$state', 'SETTINGS_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/settings/settings-menu.html',
		scope:{
			disableSearchBar:'='
		},
		controller:SettingsMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}