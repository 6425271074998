class DashboardController
{
	constructor($state)
	{
		Object.assign(this, {$state});
	}

	$onInit()
	{

	}
}
DashboardController.$inject = ['$state'];

const DashboardComponent = {
	templateUrl: '/views/dashboard.html',
	controller: DashboardController,
	bindings:{
		'activities':'=',
		'members':'='
	}
};

export default DashboardComponent;