import HistoryResource from './HistoryResource';
import HistoryComponent from './HistoryComponent';
import historyMenuDirective from './HistoryMenuDirective';
import historyTableDirective from './HistoryTableDirective';

export default angular.module('history.module', [])
.constant('HISTORY_MENU_LINKS', [
	
])
.provider('HistoryResource', HistoryResource)
.component('historys', HistoryComponent)
.directive('historyMenu', historyMenuDirective)
.directive('historyTable', historyTableDirective);