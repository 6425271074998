class MemberActivitiesDirectiveController{
	constructor($filter, $http, DISTRICTS, REMOTE_BASE_URL, ActivityMemberResource, ActivityResource, MembersComponent){//GCN

		this.$http = $http;
		this.$filter = $filter;
        this.DISTRICTS = DISTRICTS;//CGN
		this.REMOTE_BASE_URL =REMOTE_BASE_URL;
 
        this.ActivityMemberResource = ActivityMemberResource;//CGN

		this.activities = [];
		$http.get(`${REMOTE_BASE_URL}/v2/activities/validated`).then((response)=>{
			this.activities = response.data.map((a) => new ActivityResource(a)) ;
//CGN+
			this.filter_by_member(this.activities);
//CGN-

		});

		this.member_activities = [];
		if(this.member.id){
			$http.get(`${REMOTE_BASE_URL}/v2/member/${this.member.id}/activities`).then((response)=>{
				this.member_activities = response.data;
			});
		}
		this.member_activities_id = [];


	}

    //CGN+
    getDistrictLabel(districtId){
            let d = this.DISTRICTS.filter((d) => d.id == districtId);
            return d.length > 0 ? d.shift().label : null;
        }

    filter_by_member(activities){
		var nbActivities = activities.length;
    
		for (var i=nbActivities-1 ; i>=0 ; i--){
			var activity = activities[i];
            
			if (this.member.isDesactivated()){
				activities.splice(i,1);
				continue;
			}
            
			if (!this.member.isAuthorizedForDistrict(this.getDistrictLabel(activity.districtId), this.getDistrictLabel(this.member.district_id)))
			{
				activities.splice(i,1);
				continue;                
			}
			
			if (activity.activeAt != null && activity.activeAt != ""){
				if ((activity.authorize_members_without_membership != 1) && 
					((!this.member.isAdherent) || (this.member.isAdherent && (this.member.hasPassedMaxRenewDate())))){
					activities.splice(i,1);
					continue;
				}
			
				if ((activity.isNonMemberAuthorized != 1) &&
					((!this.member.isAdherent) && (!this.member.isParticipantSenior))){
					activities.splice(i,1);
				}
			}
			else{
				if (!activity.isAuthorized(this.member)){
					activities.splice(i,1);
				}
			}
		}
	}
//CGN-

	getActivities(){
		return this.activities;
	}

	addActivityToMember(Activity){
/*		this.member_activities.push(Activity.id);
		/!* Permet de voir l'ajout du membre dans la fiche de l'activité *!/
		this.activities.forEach((val, key) => {
			if (val.id == Activity.id) {
				val.membersIds.push(this.member.id);
			}
		});*/
		/*Création d'une nouvelle requête pour l'enregistrement de l'activité à l'adhérent*/
//CGN+
//		this.$http.post(`${this.REMOTE_BASE_URL }/v2/member/${this.member.id}/activities`, {'activity_id':Activity.id}).then((response) => {
//			this.member_activities.push(response.data);
//		});
		var activity_id = Activity.id;
		var member_id = this.member.id;
		var am = new this.ActivityMemberResource({
			activity_id:activity_id,
			member_id:member_id
		});
		am.save().then((response) => {
			this.member_activities.push(response);
		});
//CGN-
    }

	activitiesNotAlreadyRegistered(){
		this.member_activities.map((a)=> {this.member_activities_id.push(a.activity_id);});
		return this.getActivities().filter((a) => (a.isRegistrationRequired() && this.member_activities_id.indexOf(a.id) == -1 && a.isDateValidate()));//CGN
	}

	hasAlreadyRegisteredActivities(){
		return (this.member_activities.length > 0);
	}

	getAlreadyRegisteredActivities(){
		return this.member_activities;
	}

	toggleMemberPayment(Activity){
		if(this.registeredMemberHasPaid(Activity)){
			let i = this.member.activitiesIdsPaid.indexOf(Activity.id);
			this.member.activitiesIdsPaid.splice(i, 1);
		}else{
			this.member.activitiesIdsPaid.push(Activity.id);
		}
	}

	registeredMemberHasPaid(Activity){
		return this.member_activities.hasPaid;
	}
}
MemberActivitiesDirectiveController.$inject = ['$filter', '$http' ,'DISTRICTS', 'REMOTE_BASE_URL', 'ActivityMemberResource', 'ActivityResource'];//CGN

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/member/member-activities.html',
		scope:{
			member:'='
		},
		controller:MemberActivitiesDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}