class PageBodyEditorComponentDirectiveController{
	constructor($uibModal){
		//this.name
		//this.attributes
		this.$modal = $uibModal;
	}

	openEditModal(){
		let IConfirmModal = this.$modal.open({
			templateUrl:`/views/page/page-editor-component-${this.name}-modal.html`,
			windowClass:'component-modal',
			resolve:{
				'attributes':angular.copy(this.attributes)
			},
			controller:function($scope, attributes){
				$scope.attributes = attributes;
				$scope.tinymceOptions = {
					plugins: 'link,code',
					menubar: false,
					toolbar: 'undo redo | link | bold italic underline | code',
					statusbar: false,
					forced_root_block : ""
				};

				$scope.ok = function(){
					console.log($scope.attributes);
					$scope.$close($scope.attributes);
				};

				$scope.cancel = function(){
					$scope.$dismiss();
				};
			}
		});

		IConfirmModal.result.then((attributes) => {
			this.attributes = attributes;
		});
	}

	onDrop($event, $data)
	{
		if(this.attributes.children){
			var component = {
				name:$data.name,
				attributes:{}
			};
			if($data.droppable && $data.droppable === true){
				component.attributes.children = [];
			}
			this.attributes.children.push(component);
		}
	}

	onMove($index){
		this.attributes.children.splice($index, 1);
	}

	onCopy($index){
		console.log('copied');
	}

	deleteChild($index, component){
		this.attributes.children.splice($index, 1);
	}
}

export default function(){
	return {
		restrict:'E',
		scope:{
			'name':'=',
			'attributes':'='
		},
		template:`<div class="component-title">
				<a ng-click="$ctrl.openEditModal()"><i class="glyphicon glyphicon-pencil"></i>&nbsp;{{$ctrl.name}}</a>
				<div ng-if="$ctrl.attributes.children" class="editor-components" ui-on-drop="$ctrl.onDrop($event, $data)" dnd-list="$ctrl.attributes.children">
					<div class="editor-component" ng-repeat="component in $ctrl.attributes.children" dnd-draggable="component" dnd-copy="$ctrl.onCopy($index)" dnd-moved="$ctrl.onMove($index)" dnd-effect-allowed="copyMove">
						<editor-component name="component.name" attributes="component.attributes"></editor-component>
						<button ng-click="$ctrl.deleteChild($index, component)">X</button>
					</div>
				</div>
			</div>`,
		controller:PageBodyEditorComponentDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}