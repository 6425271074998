class HistoryController {

	constructor ($state, $filter, HISTORYS, HistoryResource) {
		this.$state = $state;
		this.$filter = $filter;
		this.history = HISTORYS;
		this.HistoryResource = HistoryResource;
	}

	getHistory() {
		return this.history;
	}

}
HistoryController.$inject = ['$state', '$filter', 'HISTORYS', 'HistoryResource'];

let HistoryComponent = {
	templateUrl: 'views/history/history.html',
	controller: HistoryController
};

export default HistoryComponent;
