class DistrictTableDirectiveController {

	constructor(REMOTE_BASE_URL, DISTRICTS) {
		this.districts = DISTRICTS;
	}

	onFileUploaded(files = [], namespace, district) {
		district.medias = district.medias.concat(files[namespace]);
		files=[];
		district.medias.photos = district.medias.filter((m) => m.namespace.split("/")[2] == 'photos');
	}

	onFileDeleted(files, district)
	{
		district.medias = [];
		district.medias.photos = district.medias.filter((m) => m.namespace.split("/")[2] == 'photos');
	}

	getDistrict() {
		return this.districts;
	}
}

DistrictTableDirectiveController.$inject = ['REMOTE_BASE_URL', 'DISTRICTS'];

export default function() {
    return {
        restrict : 'E',
		scope:{
			save:'&',
			errors:'='
		},
        templateUrl : '/views/district/district-table.html',
		controller :DistrictTableDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
    }
}
