class PublicationMenuDirectiveController
{
	constructor($state, PublicationResource, PUBLICATION_MENU_LINKS,PUBLICATIONS){//CGNTemps
		this.$state = $state;
		this.PublicationResource = new PublicationResource();
		this.links = PUBLICATION_MENU_LINKS;
		this.publications = [];
    }

	$onInit(){
//		this.sync();//CGNTemps
	}

	sync(){
//		this.PublicationResource.all().then((a) => this.publications = a);//CGNTemps
	}

}
PublicationMenuDirectiveController.$inject = ['$state', 'PublicationResource', 'PUBLICATION_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			disableSearchBar:'='
		},
		templateUrl:'/views/publication/publication-menu.html',
		controller:PublicationMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}
