class NewsMenuDirectiveController{
	constructor($state, NEWS_MENU_LINKS)
	{
		this.$state  = $state;
		this.links   = NEWS_MENU_LINKS;
	}

	onSearchItemSelected(a)
	{
		this.$state.go('actualites.edit', {id:a.id});
	}
}
NewsMenuDirectiveController.$inject = ['$state', 'NEWS_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			news:'=',
			disableSearchBar:'='
		},
		templateUrl:'/views/news/news-menu.html',
		controller:NewsMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}