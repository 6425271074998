class PagesController{
	constructor($state, PageResource){
		this.$state = $state;
		this.PageResource = new PageResource();
		this.filters = angular.extend({}, $state.params);

		this.pages = [];
		this.folders = [];
	}

	$onInit(){
		this.sync();

	}

	sync(){
		this.PageResource.all({toHierarchy:true}).then((pages) => this.pages = pages);

	}

	getRoots(){
		return this.pages;
	}

	getChildren(page){
		return page.children || [];
	}

	delete(page)
	{
		if(page.parent_id){
			this.PageResource.destroyID(page.id);
		}else {
			page.destroy().then(() => this.sync());
		}
		this.sync();
	}
}
PagesController.$inject = ['$state', 'PageResource'];

let PagesComponent = {
	templateUrl: 'views/page/pages.html',
	controller: PagesController
};

export default PagesComponent;