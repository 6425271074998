class ActivityFormDirectiveController
{
	constructor(EmployeeResource, REMOTE_BASE_URL, ACTIVITY_TYPES, DISTRICTS, MEMBERSHIP_STATUS)//CGN
	{
		this.EmployeeResource = new EmployeeResource();

		this.form = {};
		this.form.site = REMOTE_BASE_URL+'/..';//CGN
		this.form.types = ACTIVITY_TYPES;
		this.form.documents = [
			"Avis d'imposition ou de non imposition afin de bénéficier d'un tarif préférentiel",
			"Carte blanche afin de bénéficier du tarif carte blanche",
			"Certificat Médical",
			"Attestation d'assurance",
			"Licence FFRS",
			"Carte d'identité",
			"Présentation du pass vaccinal obligatoire",
		];
		this.form.difficultyOfAccess = [
			"Nécessite de marcher",
			"Présence d’escaliers",
			"Prévoir des chaussures de marche"
		];
		this.form.isFixedHourOptions = [
			{value:1, label:'Horaire Fixe'},
			{value:0, label:'Tranche Horaire'}
		];
		this.form.isPricingFreeOptions = [
			{value:'1', label:'Gratuit'},
			{value:'0', label:'Payant'}
		];
		this.form.tinymceOptions = {
			plugins: 'link image code',
			menubar: false,
			toolbar: 'undo redo | link | bold italic underline'
		};
		this.form.districts = DISTRICTS;
//CGN+
		this.form.authorizations = MEMBERSHIP_STATUS;
		this.initAuthorizations();
		if ((this.activity.activeAt == null) || (this.activity.activeAt == "")){
			this.form.authorizations_stub = [];
			for (var i = this.form.authorizations_detail.length-1 ; i >= 0; i--){
				if (this.activity.initAuthorizations().find((function(refElt){
					return function(elt){
						return refElt.id == elt;
					};
				})(this.form.authorizations_detail[i]))){
					this.form.authorizations_stub.push(this.form.authorizations_detail[i]);
					this.form.authorizations_detail.slice(i);
				}

			}

		}
		else{

			this.form.authorizations_stub = this.form.authorizations_sum.find((function(autho){
				return  function(elt){
					return autho === elt.id;
				};
			})(this.activity.initAuthorizations()));
		}
//CGN-
	}

	$onInit()
	{
		this.sync();
		moment.fn.toJSON = function() { return this.format(); }
	}

	sync()
	{
		this.EmployeeResource.all().then((e) => this.form.employees = e);
	}

	getTypes()
	{
		return this.form.types.filter((t) => (t.parent_id == null));
	}

	getSubtypes(typeId)
	{
		return this.form.types.filter((t) => (t.parent_id == typeId));
	}

//CGN+
	initAuthorizations(){
		this.form.authorizations_detail = this.form.authorizations.concat([{"id": 98, "title" : "Adhérent non renouvelé"}]);
		this.form.authorizations_sum_default = {"id": 101, "title" : "Réservé aux adhérents"};
		this.form.authorizations_sum = [];
		this.form.authorizations_sum.push(this.form.authorizations_sum_default);
		this.form.authorizations_sum = this.form.authorizations_sum.concat([{"id": 102, "title" : "Ouvert aux séniors"},{"id": 103, "title" : "Ouvert à tous"}]);
	}

	updateAuthorizations(){
		this.activity.updateAuthorizations(this.form.authorizations_stub);
	}
//CGN-

	resetSelectedSubtype()
	{
		this.activity.subtypeId = undefined;
	}

	resetActivityPrice(isFree)
	{
		if(isFree){
			this.activity.price = 0;
		}
	}

	resetPartnersCovenant(){
		this.activity.partnersCovenant = null;
	}

	resetRegistrationOptions(){
		this.activity.registrationBeginAt = null;
		this.activity.registrationEndAt = null;
		this.activity.useWaitingList = 0;
		this.activity.waitingListDateLimit = null;
//		this.activity.isNonMemberAuthorized = 0;//CGNTempo
	}

    //CGN+
	resetActiveAt(){
		if (this.activity.activeAt == ''){
			this.activity.activeAt = null;
		}

		this.initAuthorizations();

		if (this.activity.activeAt == null){
			this.form.authorizations_stub = [];
		}
		else
			this.form.authorizations_stub = this.form.authorizations_sum_default;
		this.activity.updateAuthorizations(this.form.authorizations_stub);

	}

	resetRegistrationBeginAt(){
		if (this.activity.registrationBeginAt == ''){
			this.activity.registrationBeginAt = null;
		}
	}

	resetRegistrationEndAt(){
		if (this.activity.registrationEndAt == ''){
			this.activity.registrationEndAt = null;
		}
	}

	resetWaitingListDateLimit(){
		if (this.activity.waitingListDateLimit == ''){
			this.activity.waitingListDateLimit = null;
		}
	}
    //CGN-

	onFileUploaded(files = [], namespace){
		this.activity.medias = this.activity.medias.concat(files[namespace]);
		files=[];
		this.activity.medias.private = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'private');
		this.activity.medias.public = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'public');
		this.activity.medias.photos = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'photos');
	}

	onFileDeleted(files = [])
	{
		this.activity.medias = files;
		files=[];
//CGN+
		var elts = document.getElementsByClassName('input-file');
		for(var i = 0; i <elts.length; i++)
			elts[i].value = "";
//CGN-
		this.activity.medias.private = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'private');
		this.activity.medias.public = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'public');
		this.activity.medias.photos = this.activity.medias.filter((m) => m.namespace.split("/")[2] == 'photos');
//CGN-
	}

	getActivityMediasNamespaced(namespace = '')
	{
		return this.activity.medias.filter((m) => m.namespace == namespace);
	}

	deleteFileUploaded(media)
	{
		this.activity.medias = this.activity.medias.filter((m) => m.id != media.id);
	}

	resetMultiDistrict() {
		this.activity.multiDistrict = [];
	}
}
ActivityFormDirectiveController.$inject = ['EmployeeResource', 'REMOTE_BASE_URL', 'ACTIVITY_TYPES', 'DISTRICTS', 'MEMBERSHIP_STATUS'];//CGN

export default function(){
	return {
		restrict:'E',
		scope:{
			activity:'<',
			save:'&',
			errors:'='
		},
		templateUrl:'/views/activity/activity-form.html',
		controller: ActivityFormDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	};
};
