function DatePickerDirective()
{
	return {
		restrict:'A',
		require:'^ngModel',
		link:function(scope, $element, attrs, ngModel)
		{
			ngModel.$formatters.push(function(date = '')
			{
				if(date == null || date == '') return '';
				if(typeof date == 'string'){
					date = date.slice(0, 10); // 0000-00-00
				}
				return date.split('-').reverse().join('/');
			});

			ngModel.$parsers.push(function(date = '')
			{
				if(date == null || date == '') return '';
				if(typeof date == 'string'){
					date = date.slice(0, 10); // 0000-00-00
				}
				return date.split('/').reverse().join('-');
			});

			$element.datepicker({
				closeText: "Fermer",
				prevText: "Précédent",
				nextText: "Suivant",
				currentText: "Aujourd'hui",
				monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
				monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
				dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
				dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
				dayNamesMin: [ "D","L","M","M","J","V","S" ],
				changeMonth: true,
				changeYear:true,
				weekHeader: "Sem.",
				dateFormat: "dd/mm/yy",
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				yearSuffix: "",
				yearRange: "-100:+40",
				onSelect: function(date)
				{
					ngModel.$setViewValue(date);
				}
			});
		}
	}
}

export default DatePickerDirective;