class PublicationsController{
	constructor($state, $filter, PublicationResource){
		this.$state = $state;
		this.$filter = $filter;
		this.form = {};

		this.PublicationResource = new PublicationResource();

		this.filters = angular.extend({}, $state.params);
		this.publications = [];

	}

	$onInit(){
		this.sync();
	}

	sync(){
		this.PublicationResource.all().then((publications) => this.publications = publications);
	}



	getPublications(){
		return this.$filter('filterArrayByObject')(this.publications, this.filters);
	}

	delete(publication)
	{
		publication.destroy().then(() => this.sync());
	}

}

PublicationsController.$inject = ['$state', '$filter','PublicationResource'];

const PublicationsComponent = {
	templateUrl: 'views/publication/publications.html',
	controller: PublicationsController
};

export default PublicationsComponent;
