function InputFileBtnDirective(REMOTE_BASE_URL, $http, $timeout)
{
	return {
		restrict:'E',
		require:'^ngModel',
		transclude:true,
		scope:{
			multiple: '<',
			standalone: '<',
			onChange: '&',
			allowedExtensions: '@',
			namespace:'@',
		},
		templateUrl:'/views/directives/inputFileBtn.html',
		link:function(scope, element, attrs, ctrl)
		{
			var $input   = element.find('input[type=file]');
			var $trigger = element.find('button');
			var namespace = scope.namespace;

			if(ctrl.$modelValue){
				scope.filename = ctrl.$modelValue;
			}

			$timeout(() => scope.filename = ctrl.$viewValue, 0);

			$input.on('change', function(e)
			{
				var form = new FormData();
				var file = e.target.files[0];
				if(file){
					form.append(`${namespace}[]`, file);
					$http.post(`${REMOTE_BASE_URL}/v2/medias`, form, {
						transformRequest: angular.identity,
						headers:{
							'Content-Type':undefined
						}
					})
					.then(function(response) {
						ctrl.$setViewValue(response.data[`${namespace}`].file);
						scope.filename = response.data[`${namespace}`].file.path;
						scope.onChange();
					}, function(err){
						console.log(err);
					});
				}
			});

			$trigger.on('click', function()
			{
				$input.click();
			});
		}
	}
}

export default InputFileBtnDirective;