class PublicationResource
{
	constructor(attributes = {})
	{
		this.id = attributes.id;
		this.title = attributes.title || '';
		this.file = attributes.file || [];
	}


	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/publications`)
		.success((rows) => deferred.resolve(rows.map((u) => new PublicationResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/publications/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/publications/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/publications`, this);
		}

		request
		.success((u) => deferred.resolve(new PublicationResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}


}

export default function(){
	this.$get = [
		'$http',
		'$q',
		'REMOTE_BASE_URL',
		'$timeout',
		function($http, $q, REMOTE_BASE_URL, $timeout)
		{
			PublicationResource.prototype.$http = $http;
			PublicationResource.prototype.$q = $q;
			PublicationResource.prototype.$timeout = $timeout;
			PublicationResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			return PublicationResource;
		}
	];
}
