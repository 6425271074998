class NewsResource
{
	constructor(attributes = {})
	{
		this.id       = attributes.id || null;
		this.title    = attributes.title || null;
		this.slug     = attributes.slug || null;
		this.body     = attributes.body || null;
		this.logo     = attributes.logo || null;
		this.metadesc = attributes.metadesc || null;
		this.metakeys = attributes.metakeys || null;

		this.created_at = attributes.created_at || null;
		this.updated_at = attributes.updated_at || null;
	}

	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/news`)
		.success((collection) => deferred.resolve(collection.map((u) => new NewsResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(id)
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/news/${id}`)
		.success((model) => deferred.resolve(new NewsResource(model)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/v2/news/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/v2/news`, this);
		}

		request
		.success((u) => deferred.resolve(new NewsResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/v2/news/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
}

export default function()
{
	this.$get = [
		'$q',
		'$http',
		'REMOTE_BASE_URL',
		function(
		$q,
		$http,
		REMOTE_BASE_URL
		){
			NewsResource.prototype.$q = $q;
			NewsResource.prototype.$http = $http;
			NewsResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			return NewsResource;
		}
	];
}