class PageBodyEditorToolbarItemDirectiveController{
	constructor(){

	}

	onDropSuccessHandler($event, $index){

	}
}

export default function(){
	return {
		restrict:'E',
		transclude:true,
		scope:{
			name:'@',
			droppable:'='
		},
		template:`<li
			ui-draggable="true"
			on-drop-success="$ctrl.onDropSuccessHandler($event, $index)"
			drag="{name:$ctrl.name, droppable:$ctrl.droppable}"
			class="editor-toolbar-item"
			ng-transclude>
		</li>`,
		controller:PageBodyEditorToolbarItemDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}