class MemberBillingsDirectiveController{
	constructor($filter, $http, REMOTE_BASE_URL)
	{

		this.$http = $http;
		this.$filter = $filter;
		this.REMOTE_BASE_URL =REMOTE_BASE_URL;

		this.billings = [];
	}

	$onInit()
	{
		this.$http.get(`${this.REMOTE_BASE_URL}/v2/member/${this.member.id}/billings`).then((response) => {
			this.billings = response.data;
			console.log(response);
		});
	}

	hasBillings()
	{
		return (this.billings.length > 0);
	}

	getBillings()
	{
		return this.billings;
	}

}
MemberBillingsDirectiveController.$inject = ['$filter', '$http' ,'REMOTE_BASE_URL'];

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/member/member-billings.html',
		scope:{
			member:'='
		},
		controller:MemberBillingsDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}