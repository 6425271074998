function TinymceDirective(){
    return {
        restrict:'A',
        require:'^ngModel',
        link:function(scope, element, attrs, ngModel){
            console.log(scope, element, attrs, ngModel);
            element.tinymce();
        }
    }
}

export default TinymceDirective;