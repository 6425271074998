class PageBodyEditorToolbarDirectiveController{
	constructor(){

	}
}

export default function(){
	return {
		restrict:'E',
		transclude:true,
		scope:{},
		template:'<div class="editor-toolbar"><ul ng-transclude></ul></div>',
		controller:PageBodyEditorToolbarDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}