class MemberController {
	constructor($state, $filter)
	{
		this.$state = $state;
		this.$filter = $filter;

		this.errors = {
			form:{}
		};

		this.panes = {
			current:'form',
			is:function(name){
				return (this.current === name);
			},
			set:function(name){
				return this.current = name;
			}
		};
	}

	export()
	{
		console.debug('Exporting member...');
		this.member.export().then((response) =>
		{
			let time     = new Date().getTime();
			let headers  = response.headers();
			let content  = response.data;
			let filename = `member_${time}.xls`;
			let blob     = new Blob([content], {type: headers['Content-Type']});

			return saveAs(blob, filename);
		});
	}

	save(){
		return this.member.id ? this.update() : this.create();
	}

	create(){
//CGN+
		this.updateSubscribedAt();
		this.updateEntite();//CGN
		this.updateParticipant();//CGN
		
//		if (this.member.membership && (!this.member.membership.subscribed_at || this.member.membership.subscribed_at == '')){
//			this.member.membership.subscribed_at = moment().format('YYYY-MM-DD');
//		}
//CGN-

		this.member.save().then(() => {
			this.$state.go('members', {}, {reload:true});
		}, (errors) => {
			this.errors.form = errors;
		});
	}

	update(){
		this.updateSubscribedAt();//CGN
		this.updateEntite();//CGN
		this.updateParticipant();//CGN
            
		this.member.save().then(() => {
			this.$state.go('members', {}, {reload:true});
		}, (errors) => {
			this.errors.form = errors;
		});

	}

//CGN+
	resetSubscribedAt(){
		this.member.membership.subscribed_at = null;
		this.member.membership.renewed_at = null;
		this.member.membership.membership_fee_id = null;
		this.member.membership.means_of_payment_id = null;
	}
	
	updateSubscribedAt(){
		if (this.member.membership){
			if (this.member.isAdherent){
				if ((!this.member.membership.subscribed_at) || (this.member.membership.subscribed_at == '')){
					this.member.membership.subscribed_at = moment().format('YYYY-MM-DD');
					this.member.membership.renewed_at = moment(this.member.membership.subscribed_at).add(1,'year').format('YYYY-MM-DD');
				}
			}
			else{
				this.resetSubscribedAt();
			}
		}
		else{
			this.resetSubscribedAt();
		}
	}

    updateEntite(){
        if (this.member.membership){
            if (!(this.member.isAdherentInstitutionnel || this.member.isPartenaire)){
                this.member.title = null;
                this.member.functions = null;
            }
            else{
                this.member.birthday = null;
                this.member.city_of_birth = null;
                this.member.family_situation_id = null;
                this.member.retired_at = null;
                this.member.last_activity_sector_id = null;
                this.member.last_activity_category_id = null;
                this.member.means_of_transports = null;
                this.member.authorize_image_rights = 0;//CGN
                this.member.has_driving_license = null;
                this.member.has_private_driver = null;
                this.member.urgency_contact = {'lastname' : "", 'firstname' : "",  'phone' : ""};
            }
        }
        else{
            this.member.title = null;
            this.member.functions = null;
        }
    }
    
    updateParticipant(){
        if (((this.member.membership) && (this.member.isParticipantAutre || this.member.isParticipantSenior)) ||
            (!this.member.membership)){
                this.member.means_of_transports = null;
                this.member.authorize_image_rights = 0;//CGN
                this.member.has_driving_license = null;
                this.member.has_private_driver = null;
                this.member.urgency_contact = {'lastname' : "", 'firstname' : "",  'phone' : ""};
                this.member.complementaryInformations = null;
                if (this.member.membership){
                    this.member.membership.newsletter_by_email = null;
                    this.member.membership.newsletter_by_mail = null;
                    this.member.membership.subscribed_at = null;
                }
       }
    }
//CGN-
}

MemberController.$inject = ['$state', '$filter'];

let MemberComponent = {
	templateUrl: 'views/member/member.html',
	controller: MemberController,
	bindings:{
		'member':'='
	}
};

export default MemberComponent;