class PublicationController{
	constructor($state, $filter, PublicationResource, PUBLICATIONS)
	{
		this.$state = $state;
		this.$filter = $filter;

		this.publications = PUBLICATIONS;

		this.publication = new PublicationResource();
	}

	save(){
		console.log(this.publication);
		this.publication.id ? this.update() : this.create();
	}

	update(){
		this.publication.save().then((n) => {
			this.publication = n;
			console.log(this.publications)
		});
	}

	create(){
		this.publication.save().then((n) => {
			this.$state.go('publications', {}, {reload: true});
			console.log(this.publications)
		});
	}
}
PublicationController.$inject = ['$state', '$filter', 'PublicationResource', 'PUBLICATIONS'];

const PublicationComponent = {
	templateUrl: '/views/publication/publication.html',
	controller: PublicationController
};

export default PublicationComponent;
