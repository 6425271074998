function MediasInputDirective(REMOTE_BASE_URL, $http, $timeout)
{
	return {
		restrict:'E',
		transclude:true,
		scope:{
			namespace:'@',
			onChange:'&'
		},
		templateUrl:'/views/directives/medias-input.html',
		link:function(scope, element, attrs, ctrl)
		{
			var $input   = element.find('.input-file');
			var $trigger = element.find('.input-trigger-btn');

			$input.on('change', function(e)
			{
				var form               = new FormData();
				var medias             = e.target.files;
				var namespace          = scope.namespace;
				var onChange           = scope.onChange || function noop(){};
				var formUrl            = `${REMOTE_BASE_URL}/v2/medias`;
				var formFieldNamespace = `${scope.namespace}[]`;

				if(medias.length)
				{
					for(let media of medias){
						form.append(formFieldNamespace, media);
					}

					$http.post(formUrl, form, {
						transformRequest: angular.identity,
						headers:{
							'Content-Type':undefined
						}
					})
					.then(function(response)
					{
						onChange({files:response.data});
					}, function(err){
						console.log(err);
					});
				}
			});

			$trigger.on('click', function(e)
			{
				e.preventDefault();
				$input.click();
			});
		}
	}
}

export default MediasInputDirective;