class MembersController {
	constructor($state, $http, $filter, $uibModal,/* socket,*/ SOCKET_EVENTS, DISTRICTS, MEMBERSHIP_STATUS, REMOTE_BASE_URL, MemberResource, AuthSession){
		this.$state = $state;
		this.$filter = $filter;
		this.$modal = $uibModal;
		this.$http = $http;

		//this.socket = socket;
		//this.SOCKET_EVENTS = SOCKET_EVENTS;

		this.filters = angular.extend({}, $state.params);
		console.log(this.filters);

		this.page = 0;
		this.step = 32;

		this.form = {};
		this.form.membership_status = MEMBERSHIP_STATUS;
		this.form.membership_status = this.form.membership_status.concat([{"id": 98, "title" : "Adhérent non renouvelé"}, {"id": 99, "title" : "Désactivé"}]);//CGN
		this.form.districts = DISTRICTS;

		this.districts = DISTRICTS;
		this.remoteUrl = REMOTE_BASE_URL;

		this.listeners = {};
		this.MemberResource = new MemberResource();
		this.AuthSession = AuthSession;
		
		this.form.modalFilters = false;//CGN
	}

	$onInit(){
		this.bindEvents();
	}

	$onDestroy(){
		this.unbindEvents();
	}

	bindEvents(){
		//this.listeners[this.SOCKET_EVENTS.MEMBER_CREATED] = this.socket.on(this.SOCKET_EVENTS.MEMBER_CREATED, this.memberCreatedSocketEventListener.bind(this));
		//this.listeners[this.SOCKET_EVENTS.MEMBER_UPDATED] = this.socket.on(this.SOCKET_EVENTS.MEMBER_UPDATED, this.memberUpdatedSocketEventListener.bind(this));
		//this.listeners[this.SOCKET_EVENTS.MEMBER_DELETED] = this.socket.on(this.SOCKET_EVENTS.MEMBER_DELETED, this.memberDeletedSocketEventListener.bind(this));
	}

	unbindEvents(){
		//this.socket.off(this.SOCKET_EVENTS.MEMBER_CREATED, this.listeners[this.SOCKET_EVENTS.MEMBER_CREATED]);
		//this.socket.off(this.SOCKET_EVENTS.MEMBER_UPDATED, this.listeners[this.SOCKET_EVENTS.MEMBER_UPDATED]);
		//this.socket.off(this.SOCKET_EVENTS.MEMBER_DELETED, this.listeners[this.SOCKET_EVENTS.MEMBER_DELETED]);
	}

	memberCreatedSocketEventListener(id)
	{
		console.debug('Member created', id);
		this.MemberResource.find(id)
		.then((m) => {
			this.members.push(m);
		});
	}
	memberUpdatedSocketEventListener(id){
		console.debug('Member updated', id);
		this.MemberResource.find(id).then((member) => {
			this.members = this.members.map((m) => {
				if(m.id == member.id){
					return member;
				}else{
					return m;
				}
			});
		});
	}
	memberDeletedSocketEventListener(id){
		console.debug('Member deleted');
		this.members = this.members.filter((a) => {
			return (a.id != id);
		});
	}

	paginateFirst(){
		this.paginate(0);
	}

	paginate(num){
		this.page = parseInt(num);
	}

	paginatePrevious(){
		return (this.page-1 <= 0) ? this.page = 0 : --this.page;
	}

	paginateNext(){
		return (this.page+1 >= this.getPaginateCount()-1) ? this.page = this.getPaginateCount()-1 : ++this.page;
	}

	getFilteredAndOrderedMembers(){
		let filtered = this.$filter('filterArrayByObject')(this.members, this.filters);
		filtered = this.getFilteredDeactivatedAt(filtered, this.filters);//CGN
		filtered = this.getFilteredSupInf(filtered, this.filters);//CGN
		let ordered = this.$filter('orderBy')(filtered, 'fullname');
		return ordered;
	}
//CGN+
	getFilteredDeactivatedAt(members,filters){
		let filtered = members;
		if (filters["isDeactivated"]){
			var onYearBefore = moment().subtract(1,'year').format('YYYY-MM-DD HH:mm:ss');
			filtered = filtered.filter(f => f.membership.deactivated_at >= onYearBefore);
		}
		return filtered;
	}

	getFilteredSupInf(members,filters){
		let filtered = members;
		if (filters["created_at_formated_date_sup"]){
			filtered = filtered.filter(f => f.created_at >= filters["created_at_formated_date_sup"])
		}
		
		if (filters["created_at_formated_date_inf"]){
			filtered = filtered.filter(f => f.created_at < filters["created_at_formated_date_inf"])
		}
		
		if (filters["membership_subscribed_at_formated_date_sup"]){
			filtered = filtered.filter(f => f.membership.subscribed_at >= filters["membership_subscribed_at_formated_date_sup"])
		}
		
		if (filters["membership_subscribed_at_formated_date_inf"]){
			filtered = filtered.filter(f => f.membership.subscribed_at < filters["membership_subscribed_at_formated_date_inf"])
		}

		if (filters["membership_renewed_at_formated_date_sup"]){
			filtered = filtered.filter(f => f.membership.renewed_at >= filters["membership_renewed_at_formated_date_sup"])
		}
		
		if (filters["membership_renewed_at_formated_date_inf"]){
			filtered = filtered.filter(f => f.membership.renewed_at < filters["membership_renewed_at_formated_date_inf"])
		}
		return filtered;
	}
//CGN-

	getPaginateCount(){
		return Math.ceil(this.getFilteredAndOrderedMembers().length / this.step);
	}

	getMembers(){
		let offset = this.page * this.step;
		let limit = (this.page * this.step) + this.step;
		return this.getFilteredAndOrderedMembers().slice(offset, limit);
	}

	reset(){
		console.log('test.reset');
		this.filters = angular.extend({}, this.$state.params);
		this.paginateFirst();
	}

	renewAll(){
		let ids = this.getFilteredAndOrderedMembers().map((m) => m.id);
		this.MemberResource.renewAll(ids).then((members)=> {
			members.map((_m)=> {
				this.members = this.members.map((m) =>
				{
					if (m.id == _m.id){
						return _m
					}else {
						return m;
					}
				});
			});
		});

	}

	export()
	{
		let ids = this.getFilteredAndOrderedMembers().map((m) => m.id);
		this.MemberResource.exportByIdArray(ids).then((response) => {
			let time     = new Date().getTime();
			let headers  = response.headers();
			let content  = response.data;
			let filename = `members_${time}.xls`;
			let blob     = new Blob([content], {type: headers['Content-Type']});

			return saveAs(blob, filename);
		});
	}

	canEditOrDelete(){
		return _.find(this.AuthSession.permissions, function(o) {
			return o == "*" || o == "members.*";
		});
	}



	delete(Member){
		var m = Member;
		let IConfirmModal = this.$modal.open({
			templateUrl:'/views/member/member-delete-modal.html',
			windowClass:'member-modal member-delete',
			resolve: {
				member:function(){
					return m;
				}
			},
			controller:function($scope, member){
				$scope.member = member;

				$scope.ok = function () {
					$scope.$close($scope.member);
				};

				$scope.cancel = function () {
					$scope.$dismiss();
				};
			}
		});

		IConfirmModal.result.then((Member) => {
			var id = Member.id;
			Member.destroy().then(() => this.members = this.members.filter((m) => !(m.id == id)));
		});
	}

	renew(Member){
		var m = Member;
		let IConfirmModal = this.$modal.open({
			templateUrl:'/views/member/member-renew-modal.html',
			windowClass:'member-modal member-renew',
			resolve: {
				member:function(){
					return m;
				}
			},
			controller:function($scope, member, MEMBERSHIP_STATUS, MEMBERSHIP_FEES, MEANS_OF_PAYMENTS){

				$scope.member = member;
				$scope.form = {};
				$scope.form.membership_status = MEMBERSHIP_STATUS;
//				$scope.form.membership_status = this.form.membership_status.concat([{"id": 98, "title" : "Adhérent non renouvelé"}, {"id": 99, "title" : "Désactivé"}]);//CGN
//CGN+
//				$scope.form.membership_fees = MEMBERSHIP_FEES;
//				$scope.form.means_of_payments = MEANS_OF_PAYMENTS;
				$scope.form.membership_fees = $scope.member.membership_fees_list(MEMBERSHIP_FEES);
				$scope.form.means_of_payments = $scope.member.membership_payments_list(MEANS_OF_PAYMENTS);
//CGN-
				$scope.form.previousRenew = moment(member.membership.renewed_at).format('DD/MM/YYYY');
				if(moment(member.membership.renewed_at).isBefore(moment().subtract(1, 'year'))){
					let years = moment().diff(moment(member.membership.renewed_at), 'years', false);
					$scope.form.renewed_at = moment(member.membership.renewed_at).add(years+1, 'year').format('YYYY-MM-DD');
				}else {
					$scope.form.renewed_at = moment(member.membership.renewed_at).add(1, 'year').format('YYYY-MM-DD');
				}


				$scope.ok = function () {
					$scope.member.membership.renewed_at = $scope.form.renewed_at;
					$scope.$close($scope.member);
				};

				$scope.cancel = function () {
					$scope.$dismiss();
				};
			}
		});

		IConfirmModal.result.then((Member) => {
			Member.renew().then((_m) => {
				this.members = this.members.map((m) =>
				{
					if(m.id == _m.id)
					{
						console.log(_m);
						return _m
					}
					else{
						return m
				}
				});
			});
		});
	}

	getDistrictSlug(districtId){
		let d = this.districts.filter((d) => d.id == districtId);
		return d.length > 0 ? d.shift().slug : null;
	}

	getDistrictLabel(districtId){
		let d = this.districts.filter((d) => d.id == districtId);
		return d.length > 0 ? d.shift().label : null;
	}
}
MembersController.$inject = ['$state', '$http', '$filter', '$uibModal', /*'socket',*/ 'SOCKET_EVENTS', 'DISTRICTS', 'MEMBERSHIP_STATUS', 'REMOTE_BASE_URL', 'MemberResource', 'AuthSession'];

let MembersComponent = {
	templateUrl: '/views/member/members.html',
	controller: MembersController,
	bindings:{
		'members':'='
	}
};

export default MembersComponent;