class HistoryResource {

    constructor(attributes = {})
    {
        this.id         = attributes.id || null;
        this.text       = attributes.text;
        this.created_at = moment(attributes.created_at).format('DD/MM/YYYY HH:mm:ss');
        this.updated_at = attributes.updated_at;
    }
}

export default function() {
    this.$get = [
        '$http',
        '$httpParamSerializer',
        '$q',
        'REMOTE_BASE_URL',
        '$timeout',
        function ($http, $httpParamSerializer, $q, REMOTE_BASE_URL, $timeout) {
            HistoryResource.prototype.$http = $http;
            HistoryResource.prototype.$httpParamSerializer = $httpParamSerializer;
            HistoryResource.prototype.$q = $q;
            HistoryResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
            HistoryResource.prototype.$timeout = $timeout;
            return HistoryResource;
        }
    ];
}
