function InputFileToBase64Directive(){
	return {
		restrict:'A',
		scope:{},
		require:'^ngModel',
		link:function(scope, element, attrs, ngModel){
			element.on('change', function(e){
				let reader;
				reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onloadend = function(e){
					let base64String = e.target.result;
					ngModel.$setViewValue(base64String);
				};
			});
		}
	}
}

export default InputFileToBase64Directive;