function MediasDeleteDirective(REMOTE_BASE_URL, $http, $timeout)
{
	return {
		restrict:'E',
		transclude:true,
		scope:{
			id:'@',
			onChange:'&'
		},
		templateUrl:'/views/directives/medias-delete.html',
		link:function(scope, element, attrs, ctrl)
		{
			var $trigger = element.find('.del-trigger-btn');

			$trigger.on('click', function(e)
			{
				var form               = new FormData();
				var onChange            = scope.onChange || function noop(){};
				var formUrl            = `${REMOTE_BASE_URL}/v2/medias/`+scope.id;

				$http.delete(formUrl, form, {
					transformRequest: angular.identity,
					headers:{
					'Content-Type':undefined
					}
				})
				.then(function(response)
				{
					onChange({files:response.data});
//					e.target.files = NaN;//CGN
				}, function(err){
					console.log(err);
//					e.target.files = NaN;//CGN
				});
			});
		}
	}
}

export default MediasDeleteDirective;