class DistrictMenuDirectiveController{
    constructor($state, DISTRICT_MENU_LINKS){
        this.$state = $state;
        this.links = DISTRICT_MENU_LINKS;
    }
}
DistrictMenuDirectiveController.$inject = ['$state', 'DISTRICT_MENU_LINKS'];

export default function(){
    return {
        restrict:'E',
        scope:{
            'disableSearchBar':'='
        },
        templateUrl:'/views/district/district-menu.html',
        controller:DistrictMenuDirectiveController,
        controllerAs:'$ctrl',
        bindToController:true
    }
}
