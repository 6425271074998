export default class Eventer{
	constructor(name, isOverdriveAuthorized, events = {}){
		Object.assign(this, {name, isOverdriveAuthorized, events});
	}

	dispatch(name){
		if(this.has(name)){
			this.events[name].forEach(function(callback){
				callback();
			});
		}
	}

	on(name, callback){
		if(this.has(name)){
			this.events[name].push(callback);
		}else if(this.isOverdriveAuthorized){
			this.events[name] = [callback];
		}else{
			throw new Error(`Impossible d'associer l'évènement "${name}" à "${this.name}" car il n'existe pas dans la liste des évènements et l'overdrive n'est pas autorisé.`);
		}
	}

	has(name){
		return (name in this.events);
	}
}