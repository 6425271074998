import JsonPrettifyFilter from './filters/JsonPrettifyFilter';
import TimeStringFormatFilter from './filters/TimeStringFormatFilter';
import GroupByFilter from './filters/GroupByFilter';
import SlugifyFilter from './filters/SlugifyFilter';
import FilterArrayByObject from './filters/FilterArrayByObject';
import SanitizeFilter from './filters/SanitizeFilter';
import JoinFilter from './filters/JoinFilter';

import DistrictsFilter from './filters/DistrictsFilter';
import ActivityTypesFilter from './filters/ActivityTypesFilter';

import WildcardMatcher from './classes/WildcardMatcher';

import AgendaDirective from './directives/AgendaDirective';
import DatePickerDirective from './directives/DatePickerDirective';
import InputFileBtnDirective from './directives/InputFileBtnDirective';
import MenuDirective from './directives/MenuDirective';
import TimePickerDirective from './directives/TimePickerDirective';
import InputFileToBase64Directive from './directives/InputFileToBase64Directive';
import TinymceDirective from './directives/TinymceDirective';
import RestrictedDirective from './directives/RestrictedDirective';
import MediasInputDirective from './directives/MediasInputDirective';
import MediasDeleteDirective from './directives/MediasDeleteDirective';//CGN

import AuthModule from './components/Auth/AuthModule';
import ApplicationModule from './components/Application/ApplicationModule';
import NavigationModule from './components/Navigation/NavigationModule';
import DashboardModule from './components/Dashboard/DashboardModule';
import ActivityModule from './components/Activity/ActivityModule';
import MemberModule from './components/Member/MemberModule';
import EmployeeModule from './components/Employee/EmployeeModule';
import PageModule from './components/Page/PageModule';
import NewsletterModule from './components/Newsletter/NewsletterModule';
import DistrictModule from './components/District/DistrictModule';
import SettingsModule from './components/Settings/SettingsModule';
import NewsModule from './components/News/NewsModule';
import PublicationModule from './components/Publication/PublicationModule';
import HistoryModule from './components/History/HistoryModule';
import StatisticModule from './components/Statistic/StatisticModule';

import CacheProvider from './providers/CacheProvider';
export default angular.module('orpan', [
	'ui.router',
	'ui.select',
	'ui.bootstrap',
	'ui.tinymce',
	'angular.filter',
	'multipleDatePicker',
	AuthModule.name,
	ApplicationModule.name,
	NavigationModule.name,
	DashboardModule.name,
	ActivityModule.name,
	MemberModule.name,
	EmployeeModule.name,
	PageModule.name,
	NewsletterModule.name,
	DistrictModule.name,
	PublicationModule.name,
	SettingsModule.name,
	NewsModule.name,
	HistoryModule.name,
	StatisticModule.name,
])
.constant('SOCKET_EVENTS', {
	"ACTIVITY_CREATED":"ACTIVITY.CREATED",
	"ACTIVITY_UPDATED":"ACTIVITY.UPDATED",
	"ACTIVITY_DELETED":"ACTIVITY.DELETED",
	"ACTIVITY_VALIDATED":"ACTIVITY.VALIDATED",
	"MEMBER_CREATED":"MEMBER.CREATED",
	"MEMBER_UPDATED":"MEMBER.UPDATED",
	"MEMBER_DELETED":"MEMBER.DELETED"
})
/*.factory('socket', function ($rootScope) {
	 var socket;
	 socket = io.connect('https://www.orpan.fr', {path:"/io", secure:true});
	 socket.on('connect', function(){
	 	console.info('Connected to websocket!');
	 });
	return {
		 on: function (eventName, callback) {
		 	let handler = function () {
		 		let args = arguments;
		 		$rootScope.$apply(function () {
		 			callback.apply(socket, args);
		 		});
		 	};
		 	socket.on(eventName, handler);
		 	return handler;
		 },
		 emit: function (eventName, data, callback) {
		 	socket.emit(eventName, data, function () {
		 		let args = arguments;
		 		$rootScope.$apply(function () {
		 			if (callback) {
		 				callback.apply(socket, args);
		 			}
		 		});
		 	});
		 },
		 off: function(eventName, callback){
		 	socket.off(eventName, callback);
		 }
	};
})*/
.provider('Cache', CacheProvider)
.constant('REMOTE_BASE_URL', 'https://www.orpan.fr/api')
.constant('MENUS_ITEMS', {
	activities: [{
		icon: 'create',
		title: 'Ajouter une activité',
		state: {
			name: 'activity-create'
		}
	}, {
		icon: 'valid',
		title: 'Liste des activités à valider',
		state: {
			name: 'activities-validate'
		}
	}, {
		icon: 'consult',
		title: 'Liste des activités',
		state: {
			name: 'activities'
		}
	}],
	members: [{
		icon: 'create',
		title: 'Ajouter un contact',
		state: {
			name: 'member-create'
		}
	}, {
		icon: 'consult',
		title: 'Liste des contacts',
		state: {
			name: 'members'
		}
	}],
	newsletter: [{
		icon: 'create',
		title: 'Créer une newsletter',
		state: {
			name: 'newsletter-create'
		}
	}, {
		icon: 'consult',
		title: 'Consulter les newsletter',
		state: {
			name: 'newsletters'
		}
	}],
	website: [{
		icon: 'create',
		title: 'Créer une page',
		state: {
			name: 'page-create'
		}
	}, {
		icon: 'consult',
		title: 'Consulter les pages',
		state: {
			name: 'pages'
		}
	}],
	publication: [{
		icon: 'create',
		title: 'Ajouter une publication',
		state: {
			name: 'publication-create'
		}
	}, {
		icon: 'consult',
		title: 'Consulter les publications',
		state: {
			name: 'publications'
		}
	}],
	districts: [{
		icon: 'consult',
		title: 'Liste des quartiers',
		state: {
			name: 'quartiers'
		}
	}],
	configurations:[{
		icon: 'config',
		title: 'Configurer l\'application',
		state: {
			name: 'settings-app'
		}
	},{
		icon: 'config',
		title: 'Configurer le site internet',
		state: {
			name: 'settings-website'
		}
	}],
	history:[{
		icon: 'history',
		title: 'Configurer l\'application',
		state: {
			name: 'history'
		}
	}],
	statistic:[{
		icon :'statistic',
		title : 'Statistiques',
		state :{
			name: 'statistic'
		}
	}]
})
//.constant('MEMBERS_STATUS', ['Adhérent', 'Non adhérent'])//CGNTempo
.directive('agenda', AgendaDirective)
.directive('restricted', RestrictedDirective)
.directive('datepicker', DatePickerDirective)
.directive('inputFileBtn', InputFileBtnDirective)
.directive('menu', MenuDirective)
.directive('timepicker', TimePickerDirective)
.directive('toBase64', InputFileToBase64Directive)
.directive('tinymce', TinymceDirective)
.directive('mediasInput', MediasInputDirective)
.directive('mediasDelete', MediasDeleteDirective)//CGN
.filter('prettify', JsonPrettifyFilter)
.filter('sanitize', SanitizeFilter)
.filter('timeStringFormat', TimeStringFormatFilter)
.filter('filterArrayByObject', FilterArrayByObject)
.filter('slugify', SlugifyFilter)
.filter('join', JoinFilter)
.filter('districtsFilter', DistrictsFilter)
.filter('activityTypesFilter', ActivityTypesFilter)
.filter('moment', function(){
	return function(input){
		if(input == null){ return ''; }
		return moment(input).toDate();
	}
})
.directive('convertToNumber', function() {
	return {
		require: 'ngModel',
		link: function(scope, element, attrs, ngModel) {
			ngModel.$parsers.push(function(val) {
				return parseInt(val, 10);
			});
			ngModel.$formatters.push(function(val) {
				return '' + val;
			});
		}
	};
})
.config([
	'$stateProvider',
	'$urlRouterProvider',
	'$httpProvider',
	'AuthServiceProvider',
	'REMOTE_BASE_URL',
	'$compileProvider',
	'$provide',
	function($stateProvider, $urlRouterProvider, $httpProvider, AuthServiceProvider, REMOTE_BASE_URL, $compileProvider, $provide){
		$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|callto|phone|chrome-extension|data):/);
		$urlRouterProvider.otherwise('/connexion');
		$stateProvider
		.state('root', {
			abstract:true,
			resolve:{
				'FamilySituationsResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/family-situations`).then((response) => {
						$provide.value('FAMILY_SITUATIONS', response.data);
					});
				},
				'DistrictsResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/districts`).then((response) => {
						$provide.value('DISTRICTS', response.data);
					});
				},
				'ActivityTypesResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/activity-types`).then((response) => {
						$provide.value('ACTIVITY_TYPES', response.data);
					});
				},
				'MemberSectorsAndCategoriesResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/member-sectors-and-categories`).then((response) => {
						$provide.value('MEMBER_SECTORS_AND_CATEGORIES', response.data);
					});
				},
				'MemberIncomeBracketsResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/member-income-brackets`).then((response) => {
						$provide.value('MEMBER_INCOME_BRACKETS', response.data);
					});
				},
				'MembershipStatusResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/membership/status`).then((response) => {
						$provide.value('MEMBERSHIP_STATUS', response.data);
					});
				},
				'MembershipFeesResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/membership/fees`).then((response) => {
						$provide.value('MEMBERSHIP_FEES', response.data);
					});
				},
				'MeansOfPaymentsResolver':function($http, REMOTE_BASE_URL){
					return $http.get(`${REMOTE_BASE_URL}/means-of-payments`).then((response) => {
						$provide.value('MEANS_OF_PAYMENTS', response.data);
					});
				},
//CGNTemps+ : Deplacement pour un traitement au besoin et au dernier moment pour les items de menus concernes
//				'NewsletterResolver':function($http, REMOTE_BASE_URL, NewsletterResource){
//					return $http.get(`${REMOTE_BASE_URL}/newsletters`).then((response) => {
//						$provide.value('NEWSLETTERS', response.data.map((n) => new NewsletterResource(n)));
//					});
//				},
//				'SettingsResolver':function($http, REMOTE_BASE_URL, SettingsResource){
//					return $http.get(`${REMOTE_BASE_URL}/settings`).then((response) => {
//						$provide.value('SETTINGS', response.data.map((a) => new SettingsResource(a)));
//					});
//				},
//				'HistoryResolver':function($http, REMOTE_BASE_URL, HistoryResource){
//					return $http.get(`${REMOTE_BASE_URL}/history`).then((response) => {
//						$provide.value('HISTORYS', response.data.map((a) => new HistoryResource(a)));
//					});
//				},
//				'PublicationResolver':function($http, REMOTE_BASE_URL, PublicationResource){
//					return $http.get(`${REMOTE_BASE_URL}/publications`).then((response) => {
//						$provide.value('PUBLICATIONS', response.data.map((a) => new PublicationResource(a)));
//					});
//				}
//CGNTemps-
			}
		})
		.state('login', {
			url: '/connexion',
			views: {
				'@':{template:'<login></login>'}
			}
		})
		.state('dashboard', {
			url: '/tableau-de-bord',
			parent:'root',
			views: {
				'@':{template:'<dashboard activities="$resolve.activities" members="$resolve.members"></dashboard>'}
			},
			resolve:{
				'activities':function($http, REMOTE_BASE_URL, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/dashboard/activities`).then((response) => {
						return response.data.map((a) => new ActivityResource(a));
					});
				},
				'members':function($http, REMOTE_BASE_URL, MemberResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/dashboard/members`).then((response) => {
						return response.data.map((a) => new MemberResource(a));
					});
				}
			}
		})
		.state('activities', {
			url: '/activites',
			parent:'root',
		})
		.state('activities.list', {
			url: '/activites/valide',
			parent:'root',
			params:{
				title:null,
				date:null,
				type:null,
				district:null,
				validated:1,
				is_archived:0,
				not_publish:0
			},
			resolve:{
				'activities':function($http, REMOTE_BASE_URL, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/activities`).then((response) => {
						return response.data.map((a) => new ActivityResource(a));
					});
				}
			},
			views: {
				'@':{template:'<activities activities="$resolve.activities"></activities>'}
			},
			data: {
				restriction:'activities.index'
			}
		})
		.state('activities.validate', {
			url: '/non-valide',
			parent:'root',//CGNTemps
			params:{
				title:null,
				date:null,
				type:null,
				district:null,
				validated:0,
				is_archived:null,//CGNTemps
				not_publish:0
			},
			resolve:{
				'activities':function($http, REMOTE_BASE_URL, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/activities/no-validated`).then((response) => {
						return response.data.map((a) => new ActivityResource(a));
					});
				}
			},
			views: {
				'@':{template:'<activities activities="$resolve.activities" validate="0"></activities>'}
			},
			data: {
				restriction:'activities.validate'
			}
		})
		.state('activities.archived', {
			url: '/archives',
			parent:'root',
			params:{
				title:null,
				date:null,
				type:null,
				district:null,
				validated:1,
				is_archived:1,
				not_publish:0
			},
			resolve:{
				'activities':function($http, REMOTE_BASE_URL, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/activities/archived`).then((response) => {
						return response.data.map((a) => new ActivityResource(a));
					});
				}
			},
			views: {
				'@':{template:'<activities activities="$resolve.activities"></activities>'}
			},
			data: {
				restriction:'activities.archived'
			}
		})
		.state('activities.validate.edit', {
			url: '/{id}',
			parent:'activities.validate',
			resolve:{
				'activity':function($http, $stateParams, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/activities/` + $stateParams.id).then((response) => {
						return new ActivityResource(response.data);
					});
				}
			},
			views: {
				'@':{template:'<activity activity="$resolve.activity"></activity>'}
			},
			data: {
				restriction:'activities.validate.edit'
			}
		})
		.state('activities.create', {
			url: '/creation',
			parent:'root',
			resolve:{
				'activity':function(ActivityResource){
					return new ActivityResource();
				}
			},
			views: {
				'@':{template:'<activity activity="$resolve.activity"></activity>'}
			},
			data: {
				restriction:'activities.create'
			}
		})
		.state('activities.edit', {
			url: '/{id}',
			resolve:{
				'activity':function($http, $stateParams, ActivityResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/activities/` + $stateParams.id).then((response) => {
						return new ActivityResource(response.data);
					});
				}
			},
			views: {
				'@':{template:'<activity activity="$resolve.activity"></activity>'}
			},
			data: {
				restriction:'activities.edit'
			}
		})
		.state('members', {
			url: '/adherents',
			parent:'root',
			params:{
				number:null,
				fullname:null,
				status:null,
				district:null,
				isDeactivated:0
			},
			resolve:{
				'members':function($http, REMOTE_BASE_URL, MemberResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/members`).then((response) => {
						return response.data.map((a) => new MemberResource(a));
					});
				}
			},
			views: {
				'@':{template:'<members members="$resolve.members"></members>'}
			},
			data: {
				restriction:'members.index'
			}
		})
		.state('members.deactivited', {
			url: '/desactive',
			parent:'root',//CGN
			params:{
				number:null,
				fullname:null,
				status:null,
				district:null,
				isDeactivated:1
			},
			resolve:{
				'members':function($http, REMOTE_BASE_URL, MemberResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/members`).then((response) => {
						return response.data.map((a) => new MemberResource(a));
					});
				}
			},
			views: {
				'@':{template:'<members members="$resolve.members"></members>'}
			},
			data: {
				restriction:'members.index'
			}
		})
		.state('members.create', {
			url: '/creation',
			resolve:{
				'member':function(MemberResource){
					return new MemberResource();
				}
			},
			views: {
				'@':{template:'<member member="$resolve.member"></member>'}
			},
			data: {
				restriction:'members.create'
			}
		})
		.state('members.edit', {
			url: '/{id}',
			resolve:{
				'member':function($http, $stateParams, MemberResource){
					return $http.get(`${REMOTE_BASE_URL}/v2/members/` + $stateParams.id).then((response) => {
						return new MemberResource(response.data);
					});
				}
			},
			views: {
				'@':{template:'<member member="$resolve.member"></member>'}
			},
			data: {
				restriction:'members.edit'
			}
		})

		.state('pages', {
			url:'/pages',
			parent:'root',
			views: {
				'@':{template:'<pages></pages>'}
			},
			data: {
				restriction:'pages.index'
			}
		})
		.state('pages.create', {
			url:'/creation',
			views: {
				'@':{template:'<page></page>'}
			},
			data: {
				restriction:'pages.create'
			}
		})
		.state('pages.edit', {
			url:'/{id}',
			views: {
				'@':{template:'<page></page>'}
			},
			data: {
				restriction:'pages.edit'
			}
		})
		.state('newsletters', {
			url:'/newsletters',
			parent:'root',
//CGNTemps+
			resolve:{
				'NewsletterResolver':function($http, REMOTE_BASE_URL, NewsletterResource){
					return $http.get(`${REMOTE_BASE_URL}/newsletters`).then((response) => {
						$provide.value('NEWSLETTERS', response.data.map((a) => new NewsletterResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<newsletters></newsletters>'}
			},
			data: {
				restriction:'newsletters.index'
			}
		})
		.state('newsletters.create', {
			url:'/creation',
//CGNTemps+
			resolve:{
				'NewsletterResolver':function($http, REMOTE_BASE_URL, NewsletterResource){
					return $http.get(`${REMOTE_BASE_URL}/newsletters`).then((response) => {
						$provide.value('NEWSLETTERS', response.data.map((a) => new NewsletterResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<newsletter></newsletter>'}
			},
			data: {
				restriction:'newsletters.create'
			}
		})
		.state('newsletters.edit', {
			url:'/{id}',
//CGNTemps+
			resolve:{
				'NEWSLETTERS':function($http, REMOTE_BASE_URL, NewsletterResource){
					return $http.get(`${REMOTE_BASE_URL}/newsletters`).then((response) => {
						$provide.value('NEWSLETTERS', response.data.map((a) => new NewsletterResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<newsletter></newsletter>'}
			},
			data: {
				restriction:'newsletters.edit'
			}
		})
		.state('publications', {
			url:'/publications',
			parent:'root',
			resolve:{
				'PublicationResolver':function($http, REMOTE_BASE_URL, PublicationResource){
					return $http.get(`${REMOTE_BASE_URL}/publications`).then((response) => {
						$provide.value('PUBLICATIONS', response.data.map((a) => new PublicationResource(a)));
					});
				},
			},
			views: {
				'@':{template:'<publications></publications>'}
			},
			data: {
				restriction:'publications.index'
			}
		})
		.state('publications.create', {
			url:'/create',
//CGNTemps+
			parent:'root',
			resolve:{
				'PublicationResolver':function($http, REMOTE_BASE_URL, PublicationResource){
					return $http.get(`${REMOTE_BASE_URL}/publications`).then((response) => {
						$provide.value('PUBLICATIONS', response.data.map((a) => new PublicationResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<publication></publication>'}
			},
			data: {
				restriction:'publications.create'
			}
		})
		.state('actualites', {
			url:'/actualites',
			parent:'root',
			views: {
				'@':{template:'<actualites></actualites>'}
			},
			data: {
				restriction:'actualites.index'
			}
		})
		.state('actualites.create', {
			url:'/creation',
			resolve:{
				'news':function(NewsResource){
					return new NewsResource();
				}
			},
			views: {
				'@':{template:'<actualite news="$resolve.news"></actualite>'}
			},
			data: {
				restriction:'actualites.create'
			}
		})
		.state('actualites.edit', {
			url:'/{id}',
			resolve:{
				'news':function($stateParams, NewsResource){
					let r = new NewsResource();
					return r.find($stateParams.id).then((n) => n);
				}
			},
			views: {
				'@':{template:'<actualite news="$resolve.news"></actualite>'}
			},
			data: {
				restriction:'actualites.edit'
			}
		})
		.state('settings', {
			url:'/configuration',
			parent:'root',
//CGNTemps+
			resolve:{
				'SETTINGS':function($http, REMOTE_BASE_URL, SettingsResource){
					return $http.get(`${REMOTE_BASE_URL}/settings`).then((response) => {
						$provide.value('SETTINGS', response.data.map((a) => new SettingsResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<settings></settings>'}
			},
			data: {
				restriction:'settings.index'
			}
		})
		.state('history', {
			url:'/historique',
			parent:'root',
//CGNTemps+
			resolve:{
				'HISTORYS':function($http, REMOTE_BASE_URL, HistoryResource){
					return $http.get(`${REMOTE_BASE_URL}/history`).then((response) => {
						$provide.value('HISTORYS', response.data.map((a) => new HistoryResource(a)));
					});
				},
			},
//CGNTemps-
			views: {
				'@':{template:'<historys></historys>'}
			},
			data: {
				restriction:'history.index'
			}
		}).state('districts', {
			url:'/quartiers',
			parent:'root',
			resolve:{
				'DISTRICTS':function($http, REMOTE_BASE_URL, DistrictResource){
					return $http.get(`${REMOTE_BASE_URL}/districts`).then((response) => {
						$provide.value('DISTRICTS', response.data.map((a) => new DistrictResource(a)));
					});
				},
			},
			views: {
				'@':{template:'<districts></districts>'}
			},
			data: {
				restriction:'district.index'
			}
		})
		.state('statistic', {
			url :'/statistique',
			parent: 'root',
			views:{
				'@': {template :'<statistics></statistics>'}
			},
			data: {
				restriction:'statistic.index'
			}
		});
	}])
.run([
	'$rootScope',
	'$http',
	'$state',
	'AuthService',
	'AuthSession',
	'REMOTE_BASE_URL',
	// 'socket',
	function($rootScope, $http, $state, AuthService, AuthSession, REMOTE_BASE_URL, socket)
	{
		$rootScope.$on('$stateChangeError', function()
		{
			//console.debug('$stateChangeError', arguments)
		});

		$rootScope.$on('$locationChangeStart', function()
		{
			//console.debug('$locationChangeStart', arguments)
		});

		$rootScope.$on('$locationChangeSuccess', function()
		{
			//console.debug('$locationChangeSuccess', arguments)
		});

		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options)
		{
			if(toState.name === 'login' && AuthService.isAuthenticated()){
				//console.debug('User is authenticated, redirecting to /dashboard');
				event.preventDefault();
				$state.go('dashboard');
			}
			else if(toState.name !== 'login' && !AuthService.isAuthenticated()){
				//console.debug('User is not authenticated, redirecting to /login');
				event.preventDefault();
				$state.go('login');
			}
			else if(toState.hasOwnProperty('data') && toState.data.hasOwnProperty('restriction')){
				let m = new WildcardMatcher(AuthSession.permissions, toState.data.restriction);
				if(!m.match())
				{
					//console.debug('User is not authorized, aborting');
					event.preventDefault();
					if(fromState.name == "")
					{
						$state.go('dashboard');
					}
				}
			}
			//console.debug('$stateChangeStart', arguments)
		});

		$rootScope.$on('$stateChangeSuccess', function()
		{
			//console.debug('$stateChangeSuccess', arguments)
		});

		$rootScope.$on('$stateNotFound', function()
		{
			//console.debug('$stateNotFound', arguments)
		});
	}
]);

angular.element(document).ready(function(){
	angular.bootstrap(document, ['orpan']);
});
