function AgendaDirective(){
    return {
        restrict:'E',
        scope:{
            activity:'='
        },
        templateUrl:'/views/directives/agenda.html'
    }
}

export default AgendaDirective;