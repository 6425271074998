class PageFormDirectiveController{
	constructor(PageResource, $state){
		this.PageResource = new PageResource();
		this.$state = $state;

		this.errors = {};
		this.form = {};
	}

	$onInit(){
		this.sync();
	}

	sync(){

	}

}
PageFormDirectiveController.$inject = ['PageResource', '$state'];

export default function(){
	return {
		restrict:'E',
		scope:{
			page:'=',
			save:'='
		},
		templateUrl:'/views/page/page-form.html',
		controller:PageFormDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
};