class NewslettersController{
	constructor($state, $filter, NewsletterResource, ActivityResource, MemberResource, DISTRICTS, ACTIVITY_TYPES){
		this.$state = $state;
		this.$filter = $filter;
		this.form = {};

		this.NewsletterResource = new NewsletterResource();
		this.ActivityResource = new ActivityResource();
		this.MemberResource = new MemberResource();
		this.form.districts = DISTRICTS;
		this.form.types = ACTIVITY_TYPES;

		this.filters = angular.extend({}, $state.params);
		this.newsletters = [];

		this.exports = {
			newsletterEmailMembers:() => {
				return this.NewsletterResource.newsletterEmailMembers().then((response)=>{
					let time     = new Date().getTime();
					let headers  = response.headers();
					let content  = response.data;
					let filename = `export_newsletter_email.xls`;
					let blob     = new Blob([content], {type: headers['Content-Type']});

					return saveAs(blob, filename);
				})
			},
			members:() => {
				return this.NewsletterResource.exportMembers().then((binary) => {
					let filename = 'export_contacts.zip';
					let blob = new Blob([binary], {type: 'application/zip'});
					return saveAs(blob, filename);
				});
			},
			newsletter:(type = null)=>{
				return this.NewsletterResource.exportNewsletter(type).then((response)=>{
					let time     = new Date().getTime();
					let headers  = response.headers();
					let content  = response.data;
					let filename = `export_newsletter_email_site.xls`;
					let blob     = new Blob([content], {type: headers['Content-Type']});

					return saveAs(blob, filename);
				})
			},
			activities:(from = null, to = null, districtId = null, type = null, subtype = null) => {
				this.ActivityResource.all()
				.then((activities) => {
					if(from)
					{
						console.log('from' ,activities);
						from = moment(from);
						activities = activities.filter((a) => {
							var date = moment(a.date);
							return date.isSameOrAfter(from);
						});
						console.log('end-from',activities);
					}

					if(to)
					{
						console.log('to',activities);
						to = moment(to);
						activities = activities.filter((a) => {
							var date = moment(a.date);
							return date.isSameOrBefore(to);
						});
						console.log('end-to',activities);
					}
					if(districtId){
						console.log('district',activities);
						activities = activities.filter((a) => {
							return (districtId == a.districtId);
						});
						console.log('end-district',activities);
					}
					if(type){
						console.log('type',activities);
						activities = activities.filter((a) => {
							return (type == a.typeId);
						});
						console.log('end-type',activities);
					}
					if(subtype){
						console.log('subtype',activities);
						activities = activities.filter((a) => {
							return (subtype == a.subtypeId);
						});
						console.log('end-subtype',activities);
					}

					return activities;
				})
				.then((activities) => activities.map((a) => a.id))
				.then((ids) => this.ActivityResource.exportByIdArrayToHtml(ids))
				.then((response) => {
					let time     = new Date().getTime();
					let headers  = response.headers();
					let content  = response.data;
					let filename = `activities_${time}.html`;
					let blob     = new Blob([content], {type: headers['Content-Type']});

					return saveAs(blob, filename);
					// forEach(response.data, function(key, value){
					// 	let headers = response.headers();
					// 	let content = value;
					// 	let filename = `activities_${}`
					// });
				});
			}
		}
	}

	$onInit(){
		this.NewsletterResource.all().then((newsletters) => this.newsletters = newsletters);
	}

	getNewsletters(){
		return this.$filter('filterArrayByObject')(this.newsletters, this.filters);
	}

	delete(Newsletter)
	{
		Newsletter.destroy().then(() => this.sync());
	}

	edit(Newsletter)
	{
		this.$state.go('newsletters.edit', {id:Newsletter.id});
	}

	getTypes()
	{
		return this.form.types.filter((t) => (t.parent_id == null));
	}

	getSubtypes(typeId)
	{
		return this.form.types.filter((t) => (t.parent_id == typeId));
	}

	resetSelectedSubtype()
	{
		this.subtypeId = undefined;
	}
}
NewslettersController.$inject = ['$state', '$filter','NewsletterResource', 'ActivityResource', 'MemberResource', 'DISTRICTS', 'ACTIVITY_TYPES'];

const NewslettersComponent = {
	templateUrl: 'views/newsletter/newsletters.html',
	controller: NewslettersController
};

export default NewslettersComponent;