class HistoryMenuDirectiveController{
	constructor($state, HISTORY_MENU_LINKS){
		this.$state = $state;
		this.links = HISTORY_MENU_LINKS;
	}
}
HistoryMenuDirectiveController.$inject = ['$state', 'HISTORY_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/history/history-menu.html',
		scope:{
			disableSearchBar:'='
		},
		controller:HistoryMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}