class MemberResource {

	constructor(attributes = {})
	{
//CGN+
		this.ADH_INDIV = 1;
		this.PART_AUTRE = 2;
		this.PART_SENIOR = 3;
		this.ADH_INST = 4;
		this.PARTENAIRE = 5;
//CGN-

		this.id = attributes.id || null;
		this.number = attributes.number || '';
		this.civility = attributes.civility || 'Mme';
		this.lastname = attributes.lastname || '';
		this.firstname = attributes.firstname || '';
		this.address = attributes.address || '';
		this.additional_address = attributes.additional_address || '';
		this.zipcode = attributes.zipcode || '';
		this.city = attributes.city || '';
		this.district_id = attributes.district_id || 13;//CGN : Init Exterieur Nantes en création
		this.attachment_id = attributes.attachment_id;
		this.birthday = attributes.birthday || null;
		this.city_of_birth = attributes.city_of_birth || '';
		this.family_situation_id = attributes.family_situation_id;
		this.retired_at = attributes.retired_at || null;
		this.last_activity_sector_id = attributes.last_activity_sector_id || null;
		this.last_activity_category_id = attributes.last_activity_category_id || null;
		this.income_bracket_id = attributes.income_bracket_id || null;
		this.home_phone = attributes.home_phone || '';
		this.mobile_phone = attributes.mobile_phone || '';
		this.email1 = attributes.email1 || '';
		this.email2 = attributes.email2 || '';
		//this.password = attributes.password || '';
		this.means_of_transports = attributes.means_of_transports || [];
		this.has_driving_license = attributes.has_driving_license || 0;
		this.has_private_driver = attributes.has_private_driver || 0;

		this.accept_receive_newsletter = attributes.accept_receive_newsletter || 0;
		this.accept_receive_newsletter = attributes.accept_receive_newsletter || 0;
		this.accept_contact_by_email = attributes.accept_contact_by_email || 0;
		this.accept_contact_by_sms = attributes.accept_contact_by_sms || 0;
		this.accept_contact_by_letter = attributes.accept_contact_by_letter || 0;

		this.authorize_image_rights = attributes.authorize_image_rights || 0;

		this.is_member_of_general_meeting = attributes.is_member_of_general_meeting || null;
//CGN+
		this.CA = attributes.CA || null;
		this.Bureau = attributes.Bureau || null;
		this.MD = attributes.MD || null;
		this.EchSa = attributes.EchSa || null;
		this.RBPx = attributes.RBPx || null;
		this.NR = attributes.NR || null;
		this.SD = attributes.SD || null;
		this.SIG = attributes.SIG || null;
		this.Divers = attributes.Divers || null;
//CGN-
		this.title = attributes.title || '';
		this.functions = attributes.functions || '';
		this.generalMeetingPeople = attributes.generalMeetingPeople;
		this.volunteerType = attributes.volunteerType || '';
		this.profilePicture = attributes.profilePicture || null;
		this.complementaryInformations = attributes.complementaryInformations || null;

		this.membership = attributes.membership || {'membership_status_id':this.PART_AUTRE};//CGN : Initialisation à Partcipant-Autre
		this.urgency_contact = attributes.urgency_contact || {};
		this.activitiesIds = attributes.activitiesIds || [];
		this.activitiesIdsPaid = attributes.activitiesIdsPaid || [];
		this.isRenewable = attributes.isRenewable || 0;

		this.created_at = attributes.created_at || null;
		this.updated_at = attributes.updated_at || null;
		
	}

	get membership_status_title(){
		if(this.membership.status){
//CGN+
//			return this.membership.status.title;
			if (this.isDeactivated){
				return 'Désactivé';
			}
			else if (!this.membership){
				return 'Participant autre';
			}
			else if (this.isAdherent && this.hasPassedMaxRenewDate()){
				return 'Adhérent non renouvelé';
			}
			else{
				return this.membership.status.title;
			}
//CGN-
		}
		return '';
	}

	get membership_renewed_at_formated_date(){
		if(this.membership.renewed_at == null){
			return '--/--/----';
		}
		return moment(this.membership.renewed_at).format('YYYY-MM-DD');
	}

	get membership_subscribed_at_formated_date(){
//CGN+
		if(this.membership.subscribed_at == null){
			return '--/--/----';
		}
//CGN-
		return moment(this.membership.subscribed_at).format('YYYY-MM-DD');
	}

//CGN+
	get created_at_formated_date(){
		if(this.created_at == null){
			return '--/--/----';
		}
		return moment(this.created_at).format('YYYY-MM-DD');
	}
//CGN-

	get membership_newsletter_by_email(){
		return this.membership.newsletter_by_email;
	}
	get membership_newsletter_by_mail(){
		return this.membership.newsletter_by_mail;
	}

	get fullname(){
		return `${this.lastname} ${this.firstname}`;
	}
//CGN+  
	get status_id()
	{
		if(this.membership){
			return this.membership.membership_status_id;
		}
		return 2;
	}
	
	get isAdherent()
	{
		if(this.membership){
			return (this.isAdherentIndividuel || this.isAdherentInstitutionnel);
		}
		return 0;
	}
    
	get isAdherentIndividuel()
	{
		if(this.membership){
			return this.membership.membership_status_id == this.ADH_INDIV;
		}
		return 0;
	}
   
	get isParticipantSenior()
	{
		if(this.membership){
			return this.membership.membership_status_id == this.PART_SENIOR;
		}
		return true;
	} 

	setParticipantSenior()
	{
		if(this.membership){
			this.membership.membership_status_id = this.PART_SENIOR;
		}
	} 

	get isParticipantAutre()
	{
		if(this.membership){
			return this.membership.membership_status_id == this.PART_AUTRE;
		}
		return true;
	} 
	
	setParticipantAutre()
	{
		if(this.membership){
			this.membership.membership_status_id = this.PART_AUTRE;
		}
	} 

	get isAdherentInstitutionnel()
	{
		if(this.membership){
			return this.membership.membership_status_id == this.ADH_INST;
		}
		return 0;
	}

	get isPartenaire()
	{
		if(this.membership){
			return this.membership.membership_status_id == this.PARTENAIRE; 
		}
		return 0;
	}
	
	membership_income_brackets_list(listInit){
		if (this.membership){
			return this.income_brackets_filteredlist(listInit,this.status_id);
		}
		else{
			return this.income_brackets_listVide();
		}
	}
	
	income_brackets_listVide(){
		return [];
	}
	
	income_brackets_filteredlist(listInit,status_id){
		var listCompute = [];
		listCompute = listInit.filter(function(income_bracket){
			return ((income_bracket.status_id_list & ( 1 << (status_id-1) )) != 0);
		}
		,status_id);
		
		return(listCompute);
	}

	membership_fees_list(listInit){
		if (this.isAdherent || this.isParticipantSenior){
			return this.membership_fees_filteredlist(listInit,this);
		}
		else{
			return this.membership_fees_listVide();
		}
	}
	
	membership_fees_listVide(){
		return [];
	}
	
	membership_fees_filteredlist(listInit, member){
		var listCompute = [];
		listCompute = listInit.filter(function(fee){
			return (((fee.title.indexOf("Cotisation Individuelle") != -1) && (member.isAdherentIndividuel || member.isParticipantSenior)) || ((fee.title.indexOf("Cotisation Association") != -1) && (member.isAdherentInstitutionnel)));
		},member);
		
		return(listCompute);
	}
	
	membership_payments_list(listInit){
		if (this.isAdherent){
			return this.membership_payments_filteredlist(listInit,this.status_id);
		}
		else{
			return this.membership_payments_listVide();
		}
	}
	
	membership_payments_listVide(){
		return [];
	}
	
	membership_payments_filteredlist(listInit,status_id){
		var listCompute = [];
		listCompute = listInit.filter(function(payment){
			return ((payment.status_id == status_id) || (payment.status_id == 0));
		}
		,status_id);
		
		return(listCompute);
	}

	initIncomeBracket(listInit){
		if (this.income_bracket_id == null)
			if(listInit.length == 1)
				this.income_bracket_id = listInit[0].id; 
			else
				this.income_bracket_id = 4; //CGN : Init HN
	}

	resetIncomeBracket(listInit){
		this.income_bracket_id = null; 
	}
	
	resetMembershipFees(){
		if(this.membership && (!(this.isAdherent || this.isParticipantSenior))){//CGN
			this.membership.membership_fee_id = null; 
		}
	}
	
	resetMembershipPayments(){
		if(this.membership){
			this.membership.means_of_payment_id = null; 
		}
	}
	
	resetSubStatus(){
		this.is_member_of_general_meeting = null;
		this.CA = null;
		this.Bureau = null;
		this.MD = null;
		this.EchSa = null;
		this.RBPx = null;
		this.NR = null;
		this.SD = null;
		this.SIG = null;
		this.Divers = null;
	}
//CGN-    

	get fulladdress(){
		return `${this.address} ${this.zipcode} ${this.city}`;
	}

	get isDeactivated()
	{
		if(this.membership){
			return this.membership.is_deactivated;
		}
		return 0;
	}

	isMembershipFeeOk(membership_fee_id){
		if (membership_fee_id == 2 || membership_fee_id == 4) {
			return true;
		}
		this.accept_contact_by_letter = null;
		return false;
	}

//CGN+
	hasRetiredDate(){
		if(this.membership)
			return (this.retired_at != null) && (this.retired_at != "");
		return 0;
	}

	hasRenewedDate(){
		if(this.membership)
			return (this.membership.renewed_at != null) && (this.membership.renewed_at != "");
		return 0;
	}
//CGN-
	
    //CGN+
    hasPassedMaxRenewDate(){
        var renewed_at = this.membership.renewed_at;
        
        if (renewed_at){
            var now = new Date();
            var date = new Date(renewed_at);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.getMonth();
            date.setMonth(date.getMonth()+1);
            return(date < now);
        }
        return(true);
    }
		
    isDesactivated(){
        return((this.membership) && (this.membership.is_deactivated == 1));
    }
		
    hasMembership(){
        return (this.membership);
    }

    isMember(){
        return ((this.membership) && (this.membership.membership_status_id==1));
    }
    
    isAuthorizedForDistrict(ActivtyDistrictName, MemberDistrictName){
        ActivtyDistrictName = (ActivtyDistrictName == null)? 'EXT' : ActivtyDistrictName;
        MemberDistrictName = (MemberDistrictName == null)? 'EXT' : MemberDistrictName;

        if ((ActivtyDistrictName == 'EXT') || (ActivtyDistrictName == 'AGGLO') || (ActivtyDistrictName == 'NANTES'))
            return true;
        else if ((MemberDistrictName == 'EXT') || (MemberDistrictName == 'AGGLO') || (MemberDistrictName == 'NANTES'))
            return false;
        else 
            return ActivtyDistrictName == MemberDistrictName;
    }
    //CGN-
    
	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/members`)
		.success((rows) => deferred.resolve(rows.map((u) => new MemberResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(id)
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/members/${id}`)
		.success((row) => deferred.resolve(new MemberResource(row)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/v2/members/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/v2/members`, this);
		}

		request
		.success((u) => deferred.resolve(new MemberResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/members/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	renew()
	{
		var deferred = this.$q.defer();

		this.$http.post(`${this.REMOTE_BASE_URL}/members/${this.id}/renew/${this.membership.renewed_at}`,  {member:this})
		.success((u) => deferred.resolve(new MemberResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	renewAll(ids){
		var deferred = this.$q.defer();

		this.$http.post(`${this.REMOTE_BASE_URL}/members/renew/all`,  {ids:ids})
		.success((rows) => deferred.resolve(rows.map((u) => new MemberResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	export()
	{
		return this.$http.get(`${this.REMOTE_BASE_URL}/v2/member/${this.id}/export`, {responseType:'blob'});
	}

	exportByIdArray(ids = [])
	{
		return this.$http.post(`${this.REMOTE_BASE_URL}/v2/members/export`, {ids:ids}, {responseType:'blob'});
	}
}

export default function(){
	this.$get = ['$http', '$httpParamSerializer', '$httpParamSerializerJQLike', '$q', 'REMOTE_BASE_URL', function($http, $httpParamSerializer, $httpParamSerializerJQLike, $q, REMOTE_BASE_URL){
		MemberResource.prototype.$http = $http;
		MemberResource.prototype.$httpParamSerializer = $httpParamSerializer;
		MemberResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
		MemberResource.prototype.$q = $q;
		MemberResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
		return MemberResource;
	}];
}
