class MemberRenewDirectiveController{
	constructor()
	{

	}
}
MemberRenewDirectiveController.$inject = [];

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/member/member-renew.html',
		scope:{
			member:'='
		},
		controller:MemberRenewDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}