class PageBodyEditorComponentsDirectiveController{
	constructor($scope){
		/*$scope.$watch(() => this.components, function(n, o){
			console.log(n, o);
		});*/
		this.components = [];
	}

	onDrop($event, $data){
		var component = {
			name:$data.name,
			attributes:{}
		};
		if($data.droppable && $data.droppable === true){
			component.attributes.children = [];
		}
		this.components.push(component);
	}

	onMove($index){
		this.components.splice($index, 1);
	}

	onCopy($index){
		console.log('copied');
	}

	deleteComponent($index, component){
		this.components.splice($index, 1);
	}
}

export default function(){
	return {
		restrict:'E',
		transclude:true,
		scope:{
			'components':'='
		},
		template:`
		<div class="editor-components" ui-on-drop="$ctrl.onDrop($event, $data)" dnd-list="$ctrl.components">
			<div class="editor-component" ng-repeat="component in $ctrl.components" dnd-draggable="component" dnd-copied="$ctrl.onCopy($index)" dnd-moved="$ctrl.onMove($index)" dnd-effect-allowed="copyMove">
				<div class="apercu">{{ component.attributes.value }}</div>
				<editor-component name="component.name" attributes="component.attributes"></editor-component>
				<button ng-click="$ctrl.deleteComponent($index, component)">X</button>
			</div>
		</div>`,
		controller:PageBodyEditorComponentsDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}