function ActivityTypesFilter(ACTIVITY_TYPES){
	return function(id, key)
	{
		let type = _.find(ACTIVITY_TYPES, {id:id});
		if(type){
			return type[key];
		}
		return null;
	}
}

export default ActivityTypesFilter;
