export default class WildcardMatcher
{
	constructor(stringToTest, stringToMatch, splitSeparator = /[\/\.]/){
		Object.assign(this, {stringToTest, stringToMatch, splitSeparator});
	}

	match(){
		var matches = true;
		if(Array.isArray(this.stringToTest)){
			matches = this.stringToTest.filter((i) => this.check(i, this.stringToMatch)).length > 0;
		}else{
			matches = this.check(this.stringToTest, this.stringToMatch);
		}
		return matches;
	}

	check(stringToTest, stringToMatch){
		var a = stringToTest.split(this.splitSeparator);
		var b = stringToMatch.split(this.splitSeparator);

		let ii = 0;
		let isMatching = true;
		let isNegative = false;
		while(isMatching){
			if(typeof a[ii] == 'string')
			{
				if(a[ii].charAt(0) == '!'){
					isNegative = true;
					a[ii] = a[ii].slice(1, a[ii].length);
				}

				isMatching = (a[ii] == '*' || a[ii] === b[ii] || b[ii] == '*');

				if(isNegative && isMatching === true){
					isMatching = false;
				}

				if(ii >= a.length-1){
					break;
				}else{
					ii++;
				}
			}else{
				isMatching = false;
				break;
			}
		}
		return isMatching;
	}
}