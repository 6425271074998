class StatisticController {

	constructor ($state, $filter, StatisticResource) {
		this.$state = $state;
		this.$filter = $filter;
		this.StatisticResource = new StatisticResource();

		this.dlStatsGeneral = () =>
		{
			this.StatisticResource.getStatsGeneral().then((response) =>
			{
				let headers  = response.headers();
				let content  = response.data;
				let filename = `statistiques_general.xls`;
				let blob     = new Blob([content], {type: headers['Content-Type']});

				return saveAs(blob, filename);
			});
		};

		this.dlStatsDistrict = ()=>
		{
			this.StatisticResource.getStatsDistrict().then((response) =>
			{
				let headers  = response.headers();
				let content  = response.data;
				let filename = `statistiques_quartiers.xls`;
				let blob     = new Blob([content], {type: headers['Content-Type']});

				return saveAs(blob, filename);
			});
		};

		this.dlStatsActivities = ()=>
		{
			this.StatisticResource.getStatsActivities().then((response) =>
			{
				let headers  = response.headers();
				let content  = response.data;
				let filename = `statistiques_activités.xls`;
				let blob     = new Blob([content], {type: headers['Content-Type']});

				return saveAs(blob, filename);
			});
		};

		this.dlStatsMembership= () =>
		{
			this.StatisticResource.getStatsMembership().then((response) =>
			{
				let time     = new Date().getTime();
				let headers  = response.headers();
				let content  = response.data;
				let filename = `statistiques_contacts.xls`;
				let blob     = new Blob([content], {type: headers['Content-Type']});

				return saveAs(blob, filename);
			});
		};
	}
}
StatisticController.$inject = ['$state', '$filter','StatisticResource'];

let StatisticComponent = {
	templateUrl: 'views/statistic/statistic.html',
	controller: StatisticController
};

export default StatisticComponent;