function GroupByFilter(){
	return function(collection, property)
	{
		console.log(collection, property);
		if(!Array.isArray(collection) || (typeof property == 'undefined')){ return collection; }

		let r = {};
		collection.forEach(function(c){
			if(!r[property]){
				r[property] = [];
			}
			r[property].push(c);
		});
		return r;
	}
}

export default GroupByFilter;