import NewslettersComponent from './NewslettersComponent';
import NewsletterComponent from './NewsletterComponent';
import NewsletterMenuDirective from './NewsletterMenuDirective';
import NewsletterHeadingDirective from './NewsletterHeadingDirective';
import NewsletterFormDirective from './NewsletterFormDirective';
import NewslettersFiltersDirective from './NewslettersFiltersDirective';
import NewslettersTableDirective from './NewslettersTableDirective';
import NewsletterResource from './NewsletterResource';

const NewsletterModule = angular.module('newsletter.module', [])
.constant('NEWSLETTER_MENU_LINKS', [
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Créer une newsletter',
		state: {
			name: 'newsletters.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Consulter les newsletters',
		state: {
			name: 'newsletters'
		}
	}
])
.provider('NewsletterResource', NewsletterResource)
.component('newsletters', NewslettersComponent)
.component('newsletter', NewsletterComponent)
.directive('newslettersFilters', NewslettersFiltersDirective)
.directive('newslettersTable', NewslettersTableDirective)
.directive('newsletterMenu', NewsletterMenuDirective)
.directive('newsletterHeading', NewsletterHeadingDirective)
.directive('newsletterForm', NewsletterFormDirective);
export default NewsletterModule;
