import DistrictResource from './DistrictResource';
import DistrictComponent from './DistrictComponent';
import districtMenuDirective from './DistrictMenuDirective';
import districtTableDirective from './DistrictTableDirective';

export default angular.module('district.module', [])
.constant('DISTRICT_MENU_LINKS', [])
.provider('DistrictResource', DistrictResource)
.component('districts', DistrictComponent)
.directive('districtMenu', districtMenuDirective)
.directive('districtTable', districtTableDirective);
