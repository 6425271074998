class ActivityMembersDirectiveController{
	constructor($state, $filter, $uibModal, $http, DISTRICTS, REMOTE_BASE_URL, ActivityMemberResource, MemberResource){//CGN
		this.$state = $state;
		this.$filter = $filter;
		this.$modal = $uibModal;
		this.$http = $http;
        this.DISTRICTS = DISTRICTS;//CGN
		this.REMOTE_BASE_URL = REMOTE_BASE_URL;

		this.ActivityMemberResource = ActivityMemberResource;

		this.members = [];
		$http.get(`${REMOTE_BASE_URL}/v2/members`)
		.then((response) => {
			this.members = response.data.map((am) => new MemberResource(am));
//CGN+
			this.filter_by_activity(this.members);
//CGN-
		});

		this.activity_members = [];
		this.pull();
	}
	
//CGN+
    //CGN+
    getDistrictLabel(districtId){
            let d = this.DISTRICTS.filter((d) => d.id == districtId);
            return d.length > 0 ? d.shift().label : null;
        }
        
    filter_by_activity(members){
		var nbMembers = members.length;
        
        for (var i=nbMembers-1 ; i>=0 ; i--){
			var member = members[i];

			if (member.isDesactivated()){
				members.splice(i,1);
				continue;
			}

			if (!member.isAuthorizedForDistrict(this.getDistrictLabel(this.activity.districtId), this.getDistrictLabel(member.district_id)))
			{
				members.splice(i,1);
				continue;                
			}
			
			if (this.activity.activeAt != null && this.activity.activeAt != ""){
				if ((this.activity.authorize_members_without_membership != 1) && 
					((!member.isAdherent) || (member.isAdherent && (member.hasPassedMaxRenewDate())))){
					members.splice(i,1);
					continue;
				}
				
				if ((this.activity.isNonMemberAuthorized != 1) &&
					((!member.isAdherent) && (!member.isParticipantSenior))){
					members.splice(i,1);
				}
			}
			else{
				if (!this.activity.isAuthorized(member)){
					members.splice(i,1);
				}
			}
		}
	}
//CGN-

	pull (){
		if(this.activity.id != null)
		{
			return this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities/` + this.activity.id + '/members')
				.then((response) => {
					this.activity_members = response.data.map((am) => new this.ActivityMemberResource(am));
				});
		}
	}
    
//CGN+
	pullMembers (){
		if(this.activity.id != null)
		{
			return this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities/` + this.activity.id)
				.then((response) => {
                    this.activity.members = response.data.members;
				});
		}
	}
//CGN-

	addMemberToActivity(member_id){
		var activity_id = this.activity.id;
		var am = new this.ActivityMemberResource({
			activity_id:activity_id,
			member_id:member_id
		});
		am.save().then((response) => {
			this.pull();
			//this.members.unshift(response.member);
//			this.activity.members.push(response.member);//CGN
			this.pullMembers();//CGN
		});
	}
	getRegistrations(){
		return this.activity_members.filter((am)=> (am.unsubscribed_at == null));
	}
	hasRegistrations(){
		return (this.activity_members.length > 0);
	}
	checkRegistrationHasAllDocuments(registration){
		if(this.activity.hasNecessaryDocuments())
		{
			if(!Array.isArray(registration.documents)){ registration.documents = []; }

			let activity_documents_count = this.activity.necessaryDocuments.length;
			let registration_documents_count = this.activity.necessaryDocuments.filter(function(documentName){ return (registration.documents.indexOf(documentName) > -1); }).length;
			return (activity_documents_count == registration_documents_count);
		}
	}

	editWaitingList(r){
		this.$http.put(`${this.REMOTE_BASE_URL}/v2/activities/` + this.activity.id + '/members/' + r.member_id+'/waitingList')
		.then(() => {
			this.pull();
		});
	}


	editRegistration(r){
		var IConfirmModal;
		IConfirmModal = this.$modal.open({
			templateUrl:'/views/activity/activity-member-registration-modal.html',
			windowClass:'activity-modal activity-member-registration',
			resolve: {
				registration:() => { return angular.copy(r); }
			},
			controller:function($scope, $filter, registration, MEMBER_INCOME_BRACKETS, MEANS_OF_PAYMENTS/*,LIST_MEANS_OF_PAYMENTS*/)
			{
				$scope.registration = registration;

				$scope.form = {};
				$scope.form.meansOfPayments = MEANS_OF_PAYMENTS;
				//$scope.form.list_mean_payment = LIST_MEANS_OF_PAYMENTS;
				if(!$scope.registration.price)
				{
					if($scope.registration.activity.is_price_unique == 1)
					{
						$scope.registration.price = $scope.registration.activity.price;
					}
					else
					{
						if($scope.registration.pricings && Object.keys($scope.registration.pricings).length)
						{
							let incomes = MEMBER_INCOME_BRACKETS.filter(function(i)
							{
								return (i.id == $scope.registration.member.income_bracket_id);
							});
							if(incomes.length)
							{
								let income = income.shift();
								let price = $scope.registration.activity.pricings[income.label];
								if(income && price)
								{
									$scope.registration.price = price;
								}
							}
						}
					}
				}

				$scope.ok = function()
				{
					$scope.registration.hasBeenRefund = 0;
					$scope.registration.save().then((am) => {
						$scope.$close(am);
					});
				};

				$scope.refund = function()
				{
					$scope.registration.hasPaid = 0;
					$scope.registration.hasBeenRefund = 1;
					$scope.registration.save().then((am) => {
						$scope.$close(am);
					});
				};

				$scope.shouldDisplayPricingsTable = function(){
					return (!registration.activity.isPriceUnique() && Object.keys(registration.activity.pricings).length);
				};

				$scope.activityHasNecessaryDocuments = function(){
					return ($scope.registration.activity.necessaryDocuments && $scope.registration.activity.necessaryDocuments.length);
				};

				$scope.memberHasDocument = function(name){
					return ($scope.registration.documents.indexOf(name) > -1);
				};

				$scope.memberHasPaid = function(){
					return ($scope.registration.hasPaid == 1);
				};

				$scope.onPaymentStatusChange = function(){
					if($scope.registration.hasPaid == 0)
					{
						$scope.registration.hasPaid = 1;
					}else{
						$scope.registration.hasPaid = 0;
					}
				};

				$scope.cancel = function()
				{
					$scope.$dismiss();
				};

				$scope.isRegistrationDocumentChecked = function(document)
				{
					return ($scope.registration.documents.indexOf(document) > -1);
				};

				$scope.toggleDocumentSelection = function(document)
				{
					var index = $scope.registration.documents.indexOf(document);
					if (index > -1) {
						$scope.registration.documents.splice(index, 1);
					} else {
						$scope.registration.documents.push(document);
					}
				};

				$scope.getIncomeBracketFromMember = function()
				{
					var id = $scope.registration.member.income_bracket_id;
					if(id){
						var brackets = $filter('filterArrayByObject')(MEMBER_INCOME_BRACKETS, {id:id});
						if(brackets.length > 0){
							return brackets[0];
						}
					}
				};

				$scope.getActivityPriceFromMember = function()
				{
//CGN+
/*
					if($scope.registration.price){
						return $scope.registration.price;
					}
*/
//CGN-
					if($scope.registration.activity.isPricingFree){
						return 0;
					}
					if($scope.registration.activity.isPriceUnique()){
						return $scope.registration.activity.price;//CGN
					}
//CGN+
					if($scope.registration.price){
						return $scope.registration.price;
					}
//CGN-

					var income = $scope.getIncomeBracketFromMember();
					if(income){
						var key = income.label;
						var value = $scope.registration.activity.pricings[key];
						if(typeof value != 'undefined' || value != null){
							return value;
						}
					}

					return("Tranche à déclarer pour ce contact");//CGN
				};

				$scope.getDocumentValue = function(d)
				{
					return ($scope.registration.documents[d]);
				};
			}
		});
		IConfirmModal.result.then(() => { this.pull() });
	}
	deleteRegistration(r){
		var IConfirmModal = this.$modal.open({
			templateUrl:'/views/activity/activity-member-registration-delete-modal.html',
			windowClass:'activity-modal activity-member-registration activity-member-registration-delete',
			resolve: {
				registration:function(){ return r; }
			},
			controller:function($scope, $filter, registration)
			{
				$scope.registration = registration;

				$scope.delete = function () {
					$scope.registration.destroy().then(() => {
						$scope.$close(registration);
					});
				};

				$scope.cancel = function () {
					$scope.$dismiss();
				};
			}
		});

		IConfirmModal.result.then((registration) => {
			this.pull().then(() => {
//				this.activity.members = this.activity.members.filter((m) => (m.id != registration.member_id));//CGN
			this.pullMembers();//CGN
			});
		});
	}
	activityHasNecessariesDocuments(){
		return (this.activity.necessaryDocuments.length > 0);
	}
	activityUseWaitingList() {
		return (this.activity.useWaitingList);
	}
	isRegisteredMemberHasAllDocuments(am = null){
		return (this.activity.necessaryDocuments.length == am.documents.length);
	}
	export(){
		this.$http({
			url:this.REMOTE_BASE_URL + '/v2/activities/' + this.activity.id + '/members/exports',
			method:'GET',
			responseType:'blob'
		}).then(function(response){
			let time     = new Date().getTime();
			let headers  = response.headers();
			let content  = response.data;
			let filename = `activité_contacts_${time}.xls`;
			let blob     = new Blob([content], {type: headers['Content-Type']});

			return saveAs(blob, filename);
		});
	}
}
ActivityMembersDirectiveController.$inject = ['$state', '$filter', '$uibModal', '$http', 'DISTRICTS', 'REMOTE_BASE_URL', 'ActivityMemberResource', 'MemberResource'];//CGN

export default function(){
	return {
		restrict:'E',
		scope:{
			activity:'='
		},
		templateUrl:'/views/activity/activity-members.html',
		controller:ActivityMembersDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	};
};