class NavigationController{
	constructor(AuthService, AuthEvents, $state){
		this.title = 'orpan';
		this.AuthService = AuthService;
		this.AuthEvents = AuthEvents;

		this.$state = $state;

		this.navigation = {};
		this.navigation.overlay = false;

		this.user = null;
		this.displayOverlayMenu = false;
	}

	$onInit(){
		this.user = this.AuthService.user;
		this.AuthEvents.on('login', () => {
			this.user = this.AuthService.user;
		});
	}

	redirectTo(stateName, stateParams)
	{
		return this.$state.go(stateName, stateParams);
	}

	logout() {
		this.AuthService.logout().then(() => {
			this.redirectTo('login');
		});
	}

	userIsAuthenticated(){
		return this.AuthService.isAuthenticated();
	}

	toggleOverlay() {
		this.displayOverlayMenu = !this.displayOverlayMenu;
	}
}
NavigationController.$inject = ['AuthService', 'AuthEvents','$state'];

const NavigationComponent = {
	templateUrl: '/views/navigation.html',
	controller: NavigationController
};

export default NavigationComponent;