class ActivityResource
{
	constructor(attributes = {})
	{
		this.id = attributes.id;
		this.title = attributes.title || '';
		this.slug = attributes.slug || '';
		this.description = attributes.description || '';
		this.validated = attributes.validated || 0;
		this.on_front = attributes.on_front || 0;
		this.typeId = attributes.typeId || null;
		this.subtypeId = attributes.subtypeId || null;
		this.date = attributes.date || null;
		this.districtId = attributes.districtId || null;
		this.street = attributes.street || '';
		this.zipcode = attributes.zipcode || '';
		this.city = attributes.city || '';
		this.isFixedHour = attributes.isFixedHour;
		this.startHour = attributes.startHour || '';
		this.endHour =  attributes.endHour || '';
		this.isPricingFree = attributes.isPricingFree;
		this.price = attributes.price || 0;
		this.modalityOfAccess = attributes.modalityOfAccess || '';
		this.difficultyOfAccess = attributes.difficultyOfAccess || [];
		this.necessaryDocuments = attributes.necessaryDocuments || [];
		this.overallReceptionCapacity = attributes.overallReceptionCapacity || 0;
		this.roomReceptionCapacity = attributes.roomReceptionCapacity || 0;
		this.registrationMaximumCapacityFromHome = attributes.registrationMaximumCapacityFromHome || 0;
		this.registrationMaximumCapacityFromInternet = attributes.registrationMaximumCapacityFromInternet || 0;
		this.remainingPlaces = attributes.remainingPlaces || null; //virtual
		this.activeAt = attributes.activeAt || null;
		this.registrationBeginAt = attributes.registrationBeginAt || null;
		this.registrationEndAt = attributes.registrationEndAt || null;
		this.pricings = attributes.pricings || null;
		this.necessaryMaterials = attributes.necessaryMaterials || '';
		this.useWaitingList = attributes.useWaitingList || 0;
		this.waitingListDateLimit = attributes.waitingListDateLimit || null;
		this.partnersCovenant = attributes.partnersCovenant || null;
		this.ownerId = attributes.ownerId || null;
		this.ownerName = attributes.ownerName || null; // virtual
		this.is_registration_required = attributes.is_registration_required;
		this.is_price_unique = attributes.is_price_unique || 0;
		this.contactEmployeeId = attributes.contactEmployeeId || null;
		this.authorize_members_without_membership = attributes.authorize_members_without_membership || 0;
		this.is_periodic = attributes.is_periodic || 0;
		this.short_description = attributes.short_description || null;
		this.can_have_guest = attributes.can_have_guest || 0;
//		this.isNonMemberAuthorized = attributes.isNonMemberAuthorized || 0;
		if ((this.activeAt == null) || (this.activeAt == "")){
			this.isNonMemberAuthorized = null;
			this.authorizations = attributes.authorizations||null;
		}
		else{
			this.isNonMemberAuthorized = attributes.isNonMemberAuthorized || 0;
			this.authorizations = null;
		}
		this.not_publish = attributes.not_publish || 0;
		this.only_show_first = attributes.only_show_first || 0;
		this.first_activity_periodic = attributes.first_activity_periodic;
		this.registeredMembersCount = attributes.registeredMembersCount || 0; // virtual
		this.queuedMembersCount = attributes.queuedMembersCount || 0; // virtual
		this.is_archived = attributes.is_archived || 0; //virtual

		this.members = attributes.members || [];
		this.membersIds = attributes.membersIds || []; // virtual
		this.membersIdsPaid = attributes.membersIdsPaid || []; // virtual
		this.accompanyingEmployeesIds = attributes.accompanyingEmployeesIds || []; // virtual
		this.externalAccompaying = attributes.externalAccompaying || null;

		this.medias = attributes.medias || [];
		this.medias.private = this.medias.filter((m) => m.namespace.split("/")[2] == 'private');
		this.medias.public = this.medias.filter((m) => m.namespace.split("/")[2] == 'public');
		this.medias.photos = this.medias.filter((m) => m.namespace.split("/")[2] == 'photos');

		this.created_at = attributes.created_at || null;
		this.updated_at = attributes.updated_at || null;

		this.periodic = [];
		this.daysOff = [];

		if (attributes.periodic) {
			if (this.only_show_first){
				for (var i = 0; i < attributes.periodic.length; i++) {
					this.periodic.push(moment(attributes.periodic[i]));
					this.daysOff.push({date:moment(attributes.periodic[i]), css : 'on', selectable: true});
				}
			}else{
				for (var i = 0; i < attributes.periodic.length; i++) {
					this.periodic.push(moment(attributes.periodic[i]));
					this.daysOff.push({date:moment(attributes.periodic[i]), css : 'off', selectable: false});
				}
			}
		}
		this.activityDistrictIds = attributes.activityDistrictIds || [];
	}

	get address(){
		return `${this.street}, ${this.zipcode} ${this.city}`;
	}

	getRemainingPlaces(){
		if(this.hasLimitedPlaces()){
			let c = (this.overallReceptionCapacity - this.members.length);
			return (c < 0) ? 0 : c;
		}
	}

	hasLimitedPlaces(){
		return this.overallReceptionCapacity != 0;
	}

	getRemainingReceptionPlaces(){
		let c = (this.registrationMaximumCapacityFromHome - _.filter(this.members, function (member){return member.pivot!=null && member.pivot.meansOfPaymentId != 6}).length);
		return (c < 0) ? 0 : c;
	}

	getRemainingOnlinePlaces(){
		let c = (this.registrationMaximumCapacityFromInternet - _.filter(this.members, function (member){return member.pivot!=null && member.pivot.meansOfPaymentId == 6}).length);
		return (c < 0) ? 0 : c;
	}

	getCanModifyReceptionPlaces(){
        return (this.getRemainingReceptionPlaces() == this.registrationMaximumCapacityFromHome);
	}

    getCanModifyOnlinePlaces(){
        return (this.getRemainingOnlinePlaces() == this.registrationMaximumCapacityFromInternet);
	}

    getCanModifyGlobalPlaces(){
        return (this.getCanModifyReceptionPlaces() && this.getCanModifyOnlinePlaces());
	}

	isDateValidate(){
		return moment(this.date).isSameOrAfter(moment());
	}

	isRegistrationOpen() {
		return moment(this.registrationBeginAt).isSameOrBefore(moment(), 'day') && moment(this.registrationEndAt).isSameOrAfter(moment(), 'day');
	}

	hasUnlimitedPlaces(){
		return this.overallReceptionCapacity == 0;
	}

	isAddressValid(){
		return (this.street != '' && this.zipcode != '' && this.city != '');
	}

	isRegisteringValid(){
		return (this.registrationBeginAt != '' && this.registrationEndAt != '');
	}

	isOwned(){
		return (this.ownerId != null);
	}

	isDateValid(){
		return (this.date != null);
	}

	isPriceUnique(){
		return (this.is_price_unique == 1);
	}

	isRegistrationRequired(){
		return (this.is_registration_required == 1);
	}

	isNotFixedHour(){
		return (this.isFixedHour == 0);
	}


	validate(){
		var deferred = this.$q.defer();

		this.$http.put(`${this.REMOTE_BASE_URL}/activities/${this.id}/validate`)
		.success((a) => deferred.resolve(new ActivityResource(a)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	getMultiDistrict() {
		return this.activityDistrictIds;
	}

	isMultiDistrict() {
		return (this.activityDistrictIds.length > 1);
	}

	getMultiDistrictLabel(districtsList) {
		let districtsLabel = '';
		this.activityDistrictIds.forEach((id, k) => {
			let district = _.find(districtsList, function (item) {
				return item.id === id.id;
			});
			if(district){
				districtsLabel += k != 0 ?  ' ' + district['label'] : district['label'];
			}
		});

		return districtsLabel;
	}

	hasNecessaryDocuments(){
		return (this.necessaryDocuments.length > 0);
	}

	isWaitingListAllowed(){
		return (this.useWaitingList == 1);
	}

//CGN+
	initAuthorizations(){
		if ((this.activeAt == null) || (this.activeAt == "")){
			if (this.authorizations != null){
				return this.authorizations;
			}
			else{
				this.authorizations = [1,4];
				if (this.authorize_members_without_membership == 1)
					this.authorizations = [1,3,4,98];
				if (this.isNonMemberAuthorized == 1)
					this.authorizations = [1,2,3,4,5,98];
				this.authorize_members_without_membership = 0;
				this.isNonMemberAuthorized = null;
				return this.authorizations;
			}
		}
		else{
			var authorizations = 101;
			if (this.authorize_members_without_membership == 1)
				authorizations = 102;
			if (this.isNonMemberAuthorized == 1)
				authorizations = 103;
			this.authorizations = null;
			return authorizations;
		}
	}

	updateAuthorizations(authorizations_stub){
		this.authorizations = null;
		this.authorize_members_without_membership = 0;
		this.isNonMemberAuthorized = null;

		if (((this.activeAt == null) || (this.activeAt == "")) && Array.isArray(authorizations_stub)){
			authorizations_stub = authorizations_stub.sort(function(a,b){
				return a.id - b.id;
			});
			this.authorizations = authorizations_stub.map(function(elt){
				return elt.id;
			});
			this.authorize_members_without_membership = 0;
			this.isNonMemberAuthorized = null;
		}
		else if ((this.activeAt != null) && (this.activeAt != "")){
			this.authorizations = null;
            this.authorize_members_without_membership = 0;
			this.isNonMemberAuthorized = 0;
			if (authorizations_stub.id >= 102)
				this.authorize_members_without_membership = 1;
			if (authorizations_stub.id == 103)
				this.isNonMemberAuthorized = 1;
		}
	}

	isAuthorized(member){
		if(member.membership){
			return (this.authorizations.find((function(member){
						var status = member.membership.membership_status_id;
						if ((member.isAdherent) && member.hasPassedMaxRenewDate())
							status = 98;
						return function(autStatus){
							return ( autStatus == status);
						}
					})(member)
				)!= undefined);
		}
		return 0;
	}
//CGN-

	all(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities`)
		.success((rows) => deferred.resolve(rows.map((u) => new ActivityResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	allWithNotValidated(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities/not-validated`)
		.success((rows) => deferred.resolve(rows.map((u) => new ActivityResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(where, select){
		let query = {where:where,select:select};
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities?${this.$httpParamSerializerJQLike(query)}`)
		.success((rows) => deferred.resolve(rows.map((u) => new ActivityResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	first(query, select){
		return this.find(query, select).then((rows) => rows.shift());
	}

	cannotEditDate() {
		return this.first_activity_periodic != null;
	}

	save(){
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
				request = this.$http.put(`${this.REMOTE_BASE_URL}/activities/${this.id}`, this);
		} else if(this.is_periodic) {
			request = this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/create`, this);
		} else {
			request = this.$http.post(`${this.REMOTE_BASE_URL}/activities`, this);
		}

		request
		.success((u) => deferred.resolve(new ActivityResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy(){
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/activities/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	toPDF(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/activities/${this.id}/pdf`, { responseType: 'arraybuffer' })
		.success((binary) => deferred.resolve(binary))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	exports(query = {}, html = 0)
	{
		var deferred = this.$q.defer();

		if(html == 1){
			this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/export?html=1`, query, {responseType:'text'})
			.success((blob) => deferred.resolve(blob))
			.error((err) => deferred.reject(err));
		}else{
			this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/export`, query, {responseType:'blob'})
			.success((blob) => deferred.resolve(blob))
			.error((err) => deferred.reject(err));
		}

		return deferred.promise;
	}

	export()
	{
		return this.$http.get(`${this.REMOTE_BASE_URL}/v2/activities/${this.id}/export`, {responseType:'blob'});
	}

	exportByIdArray(ids = [])
	{
		return this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/export`, {ids:ids}, {responseType:'blob'});
	}

	exportByIdArrayToHtml(ids = [])
	{
		return this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/export`, {ids:ids, html:1}, {responseType:'text'});
	}
}

export default function(){
	this.$get = [
		'$http',
		'$httpParamSerializer',
		'$httpParamSerializerJQLike',
		'$q',
		'REMOTE_BASE_URL',
		'$timeout',
		'EmployeeResource',
		'MemberResource',
		function(
			$http,
			$httpParamSerializer,
			$httpParamSerializerJQLike,
			$q,
			REMOTE_BASE_URL,
			$timeout,
			EmployeeResource,
			MemberResource
		){
			ActivityResource.prototype.$http = $http;
			ActivityResource.prototype.$httpParamSerializer = $httpParamSerializer;
			ActivityResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
			ActivityResource.prototype.$q = $q;
			ActivityResource.prototype.$timeout = $timeout;
			ActivityResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			ActivityResource.prototype.EmployeeResource = new EmployeeResource();
			ActivityResource.prototype.MemberResource = MemberResource;
			return ActivityResource;
		}
	];
}
