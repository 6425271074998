class MemberFormDirectiveController{
	constructor(DISTRICTS, MEMBER_SECTORS_AND_CATEGORIES, MEMBER_INCOME_BRACKETS, FAMILY_SITUATIONS, MEMBERSHIP_STATUS, MEMBERSHIP_FEES, MEANS_OF_PAYMENTS)
	{
		this.form = {};
		this.form.structures = ["Association", "Institution", "Syndicat", "Entreprise"];
		this.form.amounts = ['10€', '14€', '20€', 'Gratuit'];
		this.form.socialnetworks = ['Facebook', 'Twitter'];
		this.form.adhesions = [
			'Retraité', 'Membre délégué(e)', 'Membre de l\'Assemblé Générale', 'Membre du Conseil d\'Administration',
			'Membre du Bureau du Conseil d\'Administration', 'Représentant d\'une structure', 'Commission A', 'Commission B',
			'Groupe de réflexion A', 'Groupe de réflexion B', 'Partenaire Professionnel'
		];
		this.form.assessments = [{
			label: 'Cotisation individuelle - 10€',
			desc: 'la cotisation permet de recevoir la newletter'
		}, {
			label: 'Cotisation individuelle - 12€',
			desc: 'permet de recevoir la newletter + la lettre d\'information postale'
		}, {
			label: 'Cotisation association - 20€',
			desc: 'permet de recevoir la newletter'
		}, {
			label: 'Cotisation association - 25€',
			desc: 'permet de recevoir la newletter + la lettre d\'information postale'
		}];
//		this.form.ag_title = ['Président', 'Présidente', 'Secretaire', 'Trésorier'];//CGN
//		this.form.ag_functions = ['Directeur', 'Directrice'];//CGN
		this.form.ag_officio_members = ['Ville de Nantes', 'Centre communal d\'action sociale'];
		this.form.ag_associated_members = ["Ville de Nantes", "Centre communal d'action sociale"];
		this.form.volunteer_types = ['Du réseau', 'Du dimanche', 'SIG', 'Accueillant du vendredi', 'Echange de savoir', 'Action quartier'];
		this.form.meansOfTransportOptions = ['Transport en commun', 'Voiture', 'Vélo', 'Proxitan'];
		this.form.generalMeetingPeople = [
			"Délégués des Caisses Complémentaires et représentants des régimes d'obligation",
			"Représentants des Comités Locaux Interprofessionnels des centrales syndicales",
			"Représentants d'Organismes ou Associations partenaires actifs (soutien financier et divers)",
			"Délégués des Association, Fédérations, Organismes agissant en faveur des Retraités et Personnes Agées",
			"Représentans d'Organismes gérant des Associations ou Etablissements offrant des services en faveur des retraités et des personnes âgées",
			"Membres délégués des Clubs, Restaurants Clubs et Associations de quartier regroupant des retraités et personnes âgées",
			"Membres délégués"
		];
		this.form.districts = DISTRICTS;
		this.form.sectors_and_categories = MEMBER_SECTORS_AND_CATEGORIES;
//CGN+
		this.form.income_brackets_bd = MEMBER_INCOME_BRACKETS;
		this.form.income_brackets = this.member.membership_income_brackets_list(MEMBER_INCOME_BRACKETS);
		this.member.initIncomeBracket(this.form.income_brackets);
//CGN-
		this.form.family_situations = FAMILY_SITUATIONS;
//CGN+
		this.form.membership_status_bd = MEMBERSHIP_STATUS;
		this.form.membership_status = this.form.membership_status_bd.concat([{"id": 98, "title" : "Adhérent non renouvelé"}, {"id": 99, "title" : "Désactivé"}]);
		this.form.membership_status_currMember = this.membership_status_list(MEMBERSHIP_STATUS);
		
		this.form.membership_fees_bd = MEMBERSHIP_FEES;
		this.form.means_of_payments_bd = MEANS_OF_PAYMENTS;
		this.form.membership_fees = this.member.membership_fees_list(MEMBERSHIP_FEES);
		this.form.means_of_payments = this.member.membership_payments_list(MEANS_OF_PAYMENTS);
		this.updateNewsletter(this.member.membership.newsletter_by_email == null, this.member.membership.newsletter_by_mail == null);
//CGN-
	}

//CGN+
	membership_status_list(listInit){
		if (!this.member.isDeactivated){
			if ((this.member.isParticipantAutre) || (this.member.isAdherentInstitutionnel) || (this.member.isPartenaire)){
				return this.membership_status_listAutreInstPart(listInit);
			}
			else if ((this.member.isParticipantSenior) || (this.member.isAdherentIndividuel)){
				return this.membership_status_listSeniorIndiv(listInit);
			}
			else{
				return this.membership_status_listVide();
			}
		}
		else{
			return this.membership_status_listVide();
		}
	}
	
	membership_status_listVide(){
		return [];
	}
	
	membership_status_listAutreInstPart(listInit){
		var listCompute = [];
		listCompute = listCompute.concat(listInit);
		listCompute.splice(2,1);
		listCompute.splice(0,1);
		
		return(listCompute);
	}
	
	membership_status_listSeniorIndiv(listInit){
		var listCompute = [];
		listCompute = listCompute.concat(listInit);
		listCompute.splice(3,2);
		listCompute.splice(1,1);
		return(listCompute);
	}
	updateStatus(){
		if (!this.member.hasRetiredDate() && (this.member.isAdherentIndividuel || this.member.isParticipantSenior)) 
			this.member.setParticipantAutre();
		else if (this.member.hasRetiredDate() && this.member.isParticipantAutre)
			this.member.setParticipantSenior();
		this.form.membership_status_currMember = this.membership_status_list(this.form.membership_status_bd);

		this.form.income_brackets = this.member.membership_income_brackets_list(this.form.income_brackets_bd);
		this.form.membership_fees = this.member.membership_fees_list(this.form.membership_fees_bd);
		this.form.means_of_payments = this.member.membership_payments_list(this.form.means_of_payments_bd);
		this.member.resetIncomeBracket(this.form.income_brackets);
		this.member.resetMembershipFees();
		this.member.resetMembershipPayments();
		this.member.resetSubStatus();//CGN
		this.member.initIncomeBracket(this.form.income_brackets);
	}
	
	
	updateNewsletter(updEmail = true, updMail = true){
		this.member.newsletter_by_mail = 0;
		if (this.member.membership.membership_fee_id != null){
			var elt = this.form.membership_fees.filter(fee => fee.id == this.member.membership.membership_fee_id)[0];
			if (elt){
				if (updMail)
					this.member.membership.newsletter_by_mail = elt.newsletter_by_mail;
				if (updEmail)
					this.member.membership.newsletter_by_email = elt.newsletter_by_email;
			}
//CGN+
			if (this.member.membership.membership_fee_id == 5)
				this.member.membership.means_of_payment_id = null;
//CGN-
		}
	}

	verifySizePicture(){
		if (this.member.profilePicture.length > (4*1024*1.4)){//Taille max 4ko
			alert("La taille de l'image est supérieure à 4ko");
			this.member.profilePicture = null;
			document.getElementById('profilePicture').value = '';
		}
	}
//CGN-

	changeSubscribed_at(){
		if ((this.member.membership.subscribed_at) && (this.member.membership.subscribed_at != ""))//CGN
			this.member.membership.renewed_at = moment(this.member.membership.subscribed_at).add(1,'year').format('YYYY-MM-DD');
		else
			this.member.membership.renewed_at = null;
	}

	submit()
	{
		return this.save();
	}

	getSectors()
	{
		return this.form.sectors_and_categories.filter((t) => (t.parent_id == null));
	}

	getSocialsCategories(sectorId)
	{
		return this.form.sectors_and_categories.filter((t) => (t.parent_id == sectorId));
	}

	resetSelectedActivityCategory()
	{
		this.member.last_activity_category_id = undefined;
	}

	deleteProfilePicture(){
		this.member.profilePicture = null;
		document.getElementById('profilePicture').value = '';//CGN
	}
}
MemberFormDirectiveController.$inject = ['DISTRICTS', 'MEMBER_SECTORS_AND_CATEGORIES', 'MEMBER_INCOME_BRACKETS', 'FAMILY_SITUATIONS', 'MEMBERSHIP_STATUS', 'MEMBERSHIP_FEES', 'MEANS_OF_PAYMENTS'];

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/member/member-form.html',
		scope:{
			member:'=',
			save:'&',
			errors:'='
		},
		controller:MemberFormDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}