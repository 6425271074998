import ActivitiesComponent from './ActivitiesComponent';
import ActivityComponent from './ActivityComponent';
import ActivityHeadingDirective from './ActivityHeadingDirective';
import ActivityMembersDirective from './ActivityMembersDirective';
import ActivityFormDirective from './ActivityFormDirective';
import ActivitiesAgendaDirective from './ActivitiesAgendaDirective';
import ActivityResource from './ActivityResource';
import ActivityMemberResource from './ActivityMemberResource';
import ActivitiesFiltersDirective from './ActivitiesFiltersDirective';
import ActivitiesTableDirective from './ActivitiesTableDirective';
import ActivityMenuDirective from './ActivityMenuDirective';

export default angular.module('activity.module', [])
.constant('ACTIVITY_MENU_LINKS', [
	{
		icon: 'create',
		title: 'Ajouter une activité',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		state: {
			name: 'activities.create'
		}
	}, {
		icon: 'valid',
		svg:`<svg version="1.1" id="Calque_1" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="46.667px" height="60px"
			 viewBox="0 -16.5 46.667 60" enable-background="new 0 -16.5 46.667 60" xml:space="preserve"><title></title><desc></desc><path d="M40.001,7.944v-7.78c0-9.204-7.48-16.664-16.667-16.664c-9.205,0-16.667,7.46-16.667,16.664v7.78
			C2.982,7.955,0,10.92,0,14.633v22.179C0,40.505,2.992,43.5,6.687,43.5h33.295c3.691,0,6.686-2.969,6.686-6.688V14.633
			C46.667,10.944,43.688,7.955,40.001,7.944z M24.445,25.531v5.766c0,0.584-0.498,1.092-1.111,1.092c-0.596,0-1.111-0.49-1.111-1.092
			v-5.766C20.927,25.075,20,23.839,20,22.389c0-1.84,1.491-3.333,3.334-3.333c1.84,0,3.333,1.493,3.333,3.333
			C26.667,23.839,25.738,25.075,24.445,25.531z M33.334,7.944h-20V0.169c0-5.538,4.478-10.002,10-10.002c5.512,0,10,4.478,10,10.002
			V7.944z"/>
		</svg>
		`,
		title: 'Liste des activités à valider',
		state: {
			name: 'activities.validate'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Liste des activités',
		state: {
			name: 'activities.list'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Liste des activités archivées',
		state: {
			name: 'activities.archived'
		}
	}
])
.provider('ActivityResource', ActivityResource)
.provider('ActivityMemberResource', ActivityMemberResource)
.component('activities', ActivitiesComponent)
.component('activity', ActivityComponent)
.directive('activityMenu', ActivityMenuDirective)
.directive('activitiesFilters', ActivitiesFiltersDirective)
.directive('activitiesTable', ActivitiesTableDirective)
.directive('activitiesAgenda', ActivitiesAgendaDirective)
.directive('activityHeading', ActivityHeadingDirective)
.directive('activityMembers', ActivityMembersDirective)
.directive('activityForm', ActivityFormDirective);
