export default class AuthSession {
	constructor($window){
		this.$store = $window.sessionStorage;
	}

	set user(value) {
		this.$store.setItem('session:user', JSON.stringify(value));
	}

	get user() {
		let user = this.$store.getItem('session:user');
		return user ? JSON.parse(user) : null;
	}

	set token(value) {
		this.$store.setItem('session:token', value);
	}

	get token() {
		let token =  this.$store.getItem('session:token');
		return token ? token : null;
	}

	set permissions(value) {
		this.$store.setItem('session:permissions', JSON.stringify(value));
	}

	get permissions() {
		let permissions = this.$store.getItem('session:permissions');
		return permissions ? JSON.parse(permissions) : null;
	}
}
AuthSession.$inject = ['$window'];