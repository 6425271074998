class StatisticResource {

    constructor(attributes = {})
    {

    }

    getStatsGeneral(){
        return this.$http.get(`${this.REMOTE_BASE_URL}/v2/statistiques/general`,   {responseType:'blob'})
    }

    getStatsMembership(){
        return this.$http.get(`${this.REMOTE_BASE_URL}/v2/statistiques/membership`,  {responseType:'blob'});
    }

    getStatsDistrict(){
        return this.$http.get(`${this.REMOTE_BASE_URL}/v2/statistiques/district`,  {responseType:'blob'});
    }

    getStatsActivities(){
        return this.$http.get(`${this.REMOTE_BASE_URL}/v2/statistiques/activities`,  {responseType:'blob'});
    }
}



export default function() {
    this.$get = [
        '$http',
        '$httpParamSerializer',
        '$q',
        'REMOTE_BASE_URL',
        '$timeout',
        function ($http, $httpParamSerializer, $q, REMOTE_BASE_URL, $timeout) {
            StatisticResource.prototype.$http = $http;
            StatisticResource.prototype.$httpParamSerializer = $httpParamSerializer;
            StatisticResource.prototype.$q = $q;
            StatisticResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
            StatisticResource.prototype.$timeout = $timeout;
            return StatisticResource;
        }
    ];
}