class PublicationFormDirectiveController{
	constructor(){

	}
}

export default function(){
	return {
		restrict:'E',
		scope:{
			publication:'=',
			save:'&'
		},
		templateUrl:'/views/publication/publication-form.html',
		controller:PublicationFormDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}
