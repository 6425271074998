export default class AuthHttpInterceptor {
	constructor($promise, $session)
	{
		Object.assign(this, {$promise, $session});

		this.request = (req) =>
		{
			if(this.$session.token != null)
			{
				req.headers['Authorization'] = `Bearer ${this.$session.token}`;
			}
			return req;
		};

		this.requestError = (err) =>
		{
			this.$promise.reject(rejection);
		};
	}
}
AuthHttpInterceptor.$inject = ['$q', 'AuthSession'];