class DistrictController {

    constructor ($state, $filter, DISTRICTS, DistrictResource) {
        this.$state = $state;
        this.$filter = $filter;
        this.districts = DISTRICTS;
        this.DistrictResource = DistrictResource;
    }

    getDistrict() {
		return this.districts;
    }
}
DistrictController.$inject = ['$state', '$filter', 'DISTRICTS', 'DistrictResource'];

let DistrictComponent = {
    templateUrl: 'views/district/district.html',
    controller: DistrictController
};

export default DistrictComponent;
