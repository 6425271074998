class NewsletterResource
{
	constructor(attributes = {})
	{
		this.id = attributes.id;
		this.title = attributes.title || '';
		this.description = attributes.description || '';
		this.attachments = attributes.attachments || [];

		this.isSent = parseInt(attributes.isSent) || 0;
		this.isElectronicMessage = attributes.isElectronicMessage || 0;
		this.isPaperMessage = attributes.isPaperMessage || 0;

		this.sent_at = attributes.sent_at || null;
		this.created_at = moment(attributes.created_at).toDate() || null;
		this.updated_at = moment(attributes.updated_at).toDate() || null;
	}


	newsletterEmailMembers(){
		return this.$http.get(`${this.REMOTE_BASE_URL}/v2/newsletters/mail/member`,  {responseType:'blob'});
	}
	exportActivities(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/newsletters/${this.id}/activities/export`, {responseType:'blob'})
		.success((blob) => deferred.resolve(blob))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	exportMembers(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/v2/newsletters/members/export`, {responseType:'blob'})
		.success((blob) => deferred.resolve(blob))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	exportNewsletter(type){
		return this.$http.get(`${this.REMOTE_BASE_URL}/v2/newsletters/mail/export/${type}`,  {responseType:'blob'});
	}

	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/newsletters`)
		.success((rows) => deferred.resolve(rows.map((u) => new NewsletterResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(where, select)
	{
		let query = {where:where,select:select};
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/newsletters?${this.$httpParamSerializerJQLike(query)}`)
		.success((rows) => deferred.resolve(rows.map((u) => new NewsletterResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	first(query)
	{
		return this.find(query).then((rows) => rows.shift());
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/newsletters/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/newsletters`, this);
		}

		request
		.success((u) => deferred.resolve(new NewsletterResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/newsletters/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
}

export default function(){
	this.$get = [
		'$http',
		'$httpParamSerializer',
		'$httpParamSerializerJQLike',
		'$q',
		'REMOTE_BASE_URL',
		'$timeout',
		function($http, $httpParamSerializer, $httpParamSerializerJQLike, $q, REMOTE_BASE_URL, $timeout)
		{
			NewsletterResource.prototype.$http = $http;
			NewsletterResource.prototype.$httpParamSerializer = $httpParamSerializer;
			NewsletterResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
			NewsletterResource.prototype.$q = $q;
			NewsletterResource.prototype.$timeout = $timeout;
			NewsletterResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			return NewsletterResource;
		}
	];
}