function DistrictsFilter(DISTRICTS){
	return function(districtIds, key)
	{
		let district = _.filter(DISTRICTS, function (item) {
			return _.find(districtIds, function (d) {
				return d.id === item.id;	
			});
		});
		if(district){
			let str = '';
			district.map(function (i, k) {
				str += k === 0 ? i[key] : ' ' + i[key];
			});
			return str;
		}
		return null;
	}
}

export default DistrictsFilter;
