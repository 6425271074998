class MemberHeadingDirectiveController{
	constructor(){

	}
}

export default function(){
	return {
		restrict:'E',
		templateUrl:'/views/member/member-heading.html',
		scope:{
			member:'='
		},
		controller:MemberHeadingDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}