import PublicationsComponent from './PublicationsComponent';
import PublicationComponent from './PublicationComponent';
import PublicationResource from './PublicationResource';
import PublicationMenuDirective from './PublicationMenuDirective';
import PublicationFormDirective from './PublicationFormDirective';
import PublicationsTableDirective from './PublicationsTableDirective';

const PublicationModule = angular.module('publication.module', [])
.constant('PUBLICATION_MENU_LINKS', [
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Ajouter une publication',
		state: {
			name: 'publications.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Consulter les publications',
		state: {
			name: 'publications'
		}
	}
])
.provider('PublicationResource', PublicationResource)
.component('publications', PublicationsComponent)
.component('publication', PublicationComponent)
.directive('publicationMenu', PublicationMenuDirective)
.directive('publicationForm', PublicationFormDirective)
.directive('publicationsTable', PublicationsTableDirective)
export default PublicationModule;
