class DistrictResource
{
    constructor(attributes = {})
    {
        this.id = attributes.id;
        this.label = attributes.label || '';
        this.title = attributes.title || '';
        this.slug = attributes.slug|| '';

        this.medias = attributes.medias || [];
        this.medias.photos = this.medias.filter((m) => m.namespace.split("/")[2] == 'photos');
    }
}

export default function(){
    this.$get = [
        '$http',
        '$httpParamSerializer',
        '$httpParamSerializerJQLike',
        '$q',
        'REMOTE_BASE_URL',
        '$timeout',
        function($http, $httpParamSerializer, $httpParamSerializerJQLike, $q, REMOTE_BASE_URL, $timeout) {
            DistrictResource.prototype.$http = $http;
            DistrictResource.prototype.$httpParamSerializer = $httpParamSerializer;
            DistrictResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
            DistrictResource.prototype.$q = $q;
            DistrictResource.prototype.$timeout = $timeout;
            DistrictResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
            return DistrictResource;
        }
    ];
}
