class PageController{
	constructor($state, PageResource){
		this.page = new PageResource();
		this.$state = $state;
		this.folders = [];
	}

	$onInit(){
		if(angular.isDefined(this.$state.params.id))
		{
			this.page.find(this.$state.params.id).then((page) => this.page = page);
		}
		this.page.getFolders().then((folders) => this.folders = folders );
	}


	save(Page){
		Page.save().then(() => this.$state.go('pages'));
	}
}
PageController.$inject = ['$state', 'PageResource'];

let PageComponent = {
	templateUrl: 'views/page/page.html',
	controller: PageController,
	controllerAs:'$ctrl'
};

export default PageComponent;