export default class AuthService {
	constructor($http, $promise, $session, $events, REMOTE_BASE_URL)
	{
		Object.assign(this, {$http, $promise, $session, $events, REMOTE_BASE_URL});

		this.loginUrl  = `${REMOTE_BASE_URL}/auth/login`;
		this.logoutUrl = `${REMOTE_BASE_URL}/auth/logout`;
		this.error = false;
	}

	login(credentials)
	{
		var p = this.$promise.defer();

		this.$http({
			url: this.loginUrl,
			method: 'POST',
			data: credentials
		})
		.success((response) =>
		{
				this.$session.token       = response.token;
				this.$session.user        = response.user;
				this.$session.permissions = response.permissions;
				this.$events.dispatch('login');
				p.resolve();
		})
		.error(() =>
		{
			p.reject();
		});

		return p.promise;
	}

	logout()
	{
		var p = this.$promise.defer();

		this.$session.token       = null;
		this.$session.user        = null;
		this.$session.permissions = null;
		this.$events.dispatch('logout');

		p.resolve();

		return p.promise;
	}

	isAuthenticated()
	{
		return (this.$session.token != null && this.$session.user != null);
	}

	get user()
	{
		return this.$session.user;
	}
}
AuthService.$inject = ['$http', '$q', 'AuthSession', 'AuthEvents', 'REMOTE_BASE_URL'];