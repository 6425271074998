class ActivityMenuDirectiveController{
	constructor($state, $filter, ACTIVITY_MENU_LINKS){
		this.$state = $state;
		this.links = ACTIVITY_MENU_LINKS;
		this.activities = $filter('filterArrayByObject')(this.activities || [], {validated:1});
		this.title = _.chain(this.links).filter(function(v){
			return this.$state.current.name == v
		}).get('title').values();
	}

	onSearchItemSelected(a){
		this.$state.go('activities.edit', {id:a.id});
	}
}
ActivityMenuDirectiveController.$inject = ['$state', '$filter', 'ACTIVITY_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			activities:'=?',
			disableSearchBar:'='
		},
		templateUrl:'/views/activity/activity-menu.html',
		controller:ActivityMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	};
}