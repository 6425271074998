class SettingsController {

	constructor ($state, $filter, SETTINGS, SettingsResource) {
		this.$state = $state;
		this.$filter = $filter;
		this.settings = SETTINGS;
		this.SettingsResource = SettingsResource;
		this.setting = {};
		this.currentEditingRow = -1;
	}

	getSettings() {
		return this.settings;
	}

	save() {
		return this.settings.id ? this.update() : this.create();
	}

	create(obj) {
		if ((obj.key == undefined || obj.key == '') && (obj.value == undefined || obj.value == '')) {
			jQuery('#spanError').prop('innerText', 'La key et la value n\'ont pas été rempli');
		}
		else if (obj.namespace == undefined) {
			jQuery('#spanError').prop('innerText', 'Le namespace n\'a pas été choisi');
		}
		else {
			jQuery('#spanError').prop('innerText', '');
			var Settings = new this.SettingsResource(obj);
			console.debug('Creating');
			Settings.save().then((s) => {
				this.setting = {};
				this.settings.push(s);
			});
		}
	}

	update(Settings) {
		if (Settings.key == '' && Settings.value == '') {
			jQuery('#spanError').prop('innerText', 'La key et la value n\'ont pas été rempli');
		}
		else {
			jQuery('#spanError').prop('innerText', '');
			console.debug('Updating');
			Settings.save().then((s) => {
				this.currentEditingRow = -1;
				Settings = s;
			});
		}
	}

	editRow($index) {
		this.currentEditingRow = $index;
	}

	delete(Settings) {
		console.debug('Deleting');
		var id = Settings.id;
		Settings.destroy().then(() => this.settings = this.settings.filter((setting) => setting.id != id));
	}
}
SettingsController.$inject = ['$state', '$filter', 'SETTINGS', 'SettingsResource'];

let SettingsComponent = {
	templateUrl: 'views/settings/settings.html',
	controller: SettingsController
};

export default SettingsComponent;