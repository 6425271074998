class ActivityMemberResource
{
	constructor(attributes = {})
	{
		this.id = attributes.id || null;
		this.activity_id = attributes.activity_id || null;
		this.member_id = attributes.member_id || null;
		this.isQueued = attributes.isQueued || 0;
		this.price = attributes.price || 0;//CGN
		this.meansOfPaymentId = attributes.meansOfPaymentId || null;
		this.final_mean_payment = attributes.final_mean_payment || null;
		this.hasPaid = attributes.hasPaid || 0;
		this.paid_at = attributes.paid_at || null;
		this.hasBeenRefund = attributes.hasBeenRefund || 0;
		this.refunded_at = attributes.refunded_at || null;
		this.documents = attributes.documents || [];
		this.subscribed_at = attributes.subscribed_at || null;
		this.unsubscribed_at = attributes.unsubscribed_at || null;

		this.activity = new this.ActivityResource(attributes.activity) || null;
		this.member = new this.MemberResource(attributes.member) || null;
	}

	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/activities-members`)
		.success((rows) => deferred.resolve(rows.map((u) => new ActivityMemberResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(where, select)
	{
		let query = {where:where,select:select};
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/activities-members?${this.$httpParamSerializerJQLike(query)}`)
		.success((rows) => deferred.resolve(rows.map((u) => new ActivityMemberResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	first(query)
	{
		return this.find(query).then((rows) => rows.shift());
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/v2/activities/${this.activity_id}/members/${this.member_id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/v2/activities/${this.activity_id}/members`, this);
		}

		request
		.success((u) => deferred.resolve(new ActivityMemberResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/v2/activities/${this.activity_id}/members/${this.member_id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroyMember(member_id){
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/v2/activities/${this.id}/members/${member_id}`)
			.success((u) => deferred.resolve(u))
			.error((err) => deferred.reject(err));

		return deferred.promise;
	}
}

export default function(){
	this.$get = [
		'$http',
		'$httpParamSerializer',
		'$httpParamSerializerJQLike',
		'$q',
		'REMOTE_BASE_URL',
		'ActivityResource',
		'MemberResource',
		function(
			$http,
			$httpParamSerializer,
			$httpParamSerializerJQLike,
			$q,
			REMOTE_BASE_URL,
			ActivityResource,
			MemberResource
		){
			ActivityMemberResource.prototype.$http = $http;
			ActivityMemberResource.prototype.$httpParamSerializer = $httpParamSerializer;
			ActivityMemberResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
			ActivityMemberResource.prototype.$q = $q;
			ActivityMemberResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			ActivityMemberResource.prototype.ActivityResource = ActivityResource;
			ActivityMemberResource.prototype.MemberResource = MemberResource;
			return ActivityMemberResource;
		}
	];
}