class NewsletterMenuDirectiveController{
	constructor($state, NEWSLETTER_MENU_LINKS){
		this.$state = $state;
		this.links = NEWSLETTER_MENU_LINKS;
	}
}
NewsletterMenuDirectiveController.$inject = ['$state', 'NEWSLETTER_MENU_LINKS'];

export default function(){
	return {
		restrict:'E',
		scope:{
			'disableSearchBar':'='
		},
		templateUrl:'/views/newsletter/newsletter-menu.html',
		controller:NewsletterMenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}
