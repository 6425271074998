class EmployeeResource
{
	constructor(attributes = {})
	{
		this.id         = attributes.id ? attributes.id : null;
		this.lastname   = attributes.lastname || null;
		this.firstname  = attributes.firstname || null;
		this.email      = attributes.email || null;
		this.created_at = attributes.created_at ? new Date(attributes.created_at) : new Date();
		this.updated_at = attributes.updated_at ? new Date(attributes.updated_at) : new Date();
	}

	get fullname()
	{
		return `${this.lastname} ${this.firstname}`;
	}

	all()
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/employees`)
		.success((employees) => deferred.resolve(employees.map((u) => new EmployeeResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(query)
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/employees?${this.$httpParamSerializer(query)}`)
		.success((employees) => deferred.resolve(employees.map((u) => new EmployeeResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	first(query)
	{
		return this.find(query).then((employees) => employees.shift());
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/employees/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/employees`, this);
		}

		request
		.success((u) => deferred.resolve(new EmployeeResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/employees/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
}

export default function()
{
	this.$get = [
		'$http',
		'$httpParamSerializer',
		'$q',
		'REMOTE_BASE_URL',
		function(
			$http,
			$httpParamSerializer,
			$q,
			REMOTE_BASE_URL
		){
			EmployeeResource.prototype.$http = $http;
			EmployeeResource.prototype.$httpParamSerializer = $httpParamSerializer;
			EmployeeResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
			EmployeeResource.prototype.$q = $q;
			return EmployeeResource;
		}
	];
}