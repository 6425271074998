class PageTreeDirectiveController
{
	constructor(PageResource, $state){
		this.PageResource = new PageResource();
		this.pages = [];
		this.$state = $state;
	}

	$onInit(){

	}

	sync(){
		this.PageResource.all({toHierarchy:true}).then((pages) => this.pages = pages);
	}

	delete(page)
	{
		console.log('test2');
		if(page.parent_id){
			this.PageResource.destroyID(page.id).then(()=> this.$state.reload());
		}else {
			page.destroy().then(() => this.$state.reload());
		}
	}


}
PageTreeDirectiveController.$inject = ['PageResource', '$state'];

export default function(){
	return {
		restrict:'E',
		scope:{
			page:'=',
			callback:'&'
		},
		templateUrl:'/views/page/page-tree.html',
		controller:PageTreeDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}