class NewsletterController{
	constructor($state, $filter, NewsletterResource, NEWSLETTERS)
	{
		this.$state = $state;
		this.$filter = $filter;

		this.newsletters = NEWSLETTERS;

		if(angular.isDefined($state.params.id)){
			this.newsletter = $filter('filterArrayByObject')(NEWSLETTERS, {id:$state.params.id})[0];
		}else{
			this.newsletter = new NewsletterResource();
		}
	}

	save(){
		console.log(this.newsletter);
		this.newsletter.id ? this.update() : this.create();
	}

	update(){
		this.newsletter.save().then((n) => {
			this.newsletter = n;
			console.log(this.newsletters)
		});
	}

	create(){
		this.newsletter.save().then((n) => {
			this.$state.go('newsletters.create', {}, {reload: true});
		}, (errors) => {
			this.errors.form = errors;
		});
	}
}
NewsletterController.$inject = ['$state', '$filter', 'NewsletterResource', 'NEWSLETTERS'];

const NewsletterComponent = {
	templateUrl: '/views/newsletter/newsletter.html',
	controller: NewsletterController
};

export default NewsletterComponent;