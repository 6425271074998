import PageResource from './PageResource';
import PagesComponent from './PagesComponent';
import PageComponent from './PageComponent';
import PageTreeDirective from './PageTreeDirective';
import PageMenuDirective from './PageMenuDirective';
import PagesFiltersDirective from './PagesFiltersDirective';
import PagesTableDirective from './PagesTableDirective';
import PageHeadingDirective from './PageHeadingDirective';
import PageFormDirective from './PageFormDirective';
import PageBodyEditorDirective from './PageBodyEditorDirective';
import PageBodyEditorToolbarDirective from './PageBodyEditorToolbarDirective';
import PageBodyEditorToolbarItemDirective from './PageBodyEditorToolbarItemDirective';
import PageBodyEditorComponentsDirective from './PageBodyEditorComponentsDirective';
import PageBodyEditorComponentDirective from './PageBodyEditorComponentDirective';
import PagesListSelectInputDirective from './PagesListSelectInputDirective';

//http://angular-dragdrop.github.io/angular-dragdrop
//http://marceljuenemann.github.io/angular-drag-and-drop-lists
let PageModule = angular.module('page.module', ['ang-drag-drop', 'dndLists'])
.constant('PAGE_MENU_LINKS', [
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Créer une page',
		state: {
			name: 'pages.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Consulter les pages',
		state: {
			name: 'pages'
		}
	},
	{
		icon: 'create',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xml:space="preserve">
			<rect x="21" width="8" height="50"/><rect y="21" width="50" height="8"/></svg>`,
		title: 'Ajouter une actualité',
		state: {
			name: 'actualites.create'
		}
	}, {
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px" y="0px" width="60px" height="55px" viewBox="0 0 60 55" style="enable-background:new 0 0 60 55;" xml:space="preserve">
		<g><rect width="60" height="10"/><rect y="23" width="60" height="10"/><rect y="45" width="60" height="10"/></g></svg>`,
		title: 'Lister les actualités',
		state: {
			name: 'actualites'
		}
	}
])
.provider('PageResource', PageResource)
.component('pages', PagesComponent)
.component('page', PageComponent)
.directive('pageTree', PageTreeDirective)
.directive('pageMenu', PageMenuDirective)
.directive('pagesFilters', PagesFiltersDirective)
.directive('pagesTable', PagesTableDirective)
.directive('pageHeading', PageHeadingDirective)
.directive('pageForm', PageFormDirective)
.directive('pageBodyEditor', PageBodyEditorDirective)
.directive('editorToolbar', PageBodyEditorToolbarDirective)
.directive('editorToolbarItem', PageBodyEditorToolbarItemDirective)
.directive('editorComponents', PageBodyEditorComponentsDirective)
.directive('editorComponent', PageBodyEditorComponentDirective)
.directive('pageSelectInput', PagesListSelectInputDirective);
export default PageModule;