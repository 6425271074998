class ActivitiesController
{
	constructor($state, $filter, $uibModal, /*socket,*/ ActivityResource, ACTIVITY_TYPES, DISTRICTS, SOCKET_EVENTS, AuthSession)
	{
		this.$state = $state;
		this.$filter = $filter;
		this.$modal = $uibModal;
		//this.socket = socket;
		this.SOCKET_EVENTS = SOCKET_EVENTS;

		this.filters = angular.extend({}, $state.params);

		this.listeners = {};

//CGN+
		this.page = 0;
		this.step = 32;
//CGN-

		this.form = {};
		this.form.types = ACTIVITY_TYPES;
		this.form.districts = DISTRICTS;
		this.AuthSession = AuthSession;

		this.ActivityResource = new ActivityResource();
	}

	bindSocketEventsListeners(){
		//this.listeners[this.SOCKET_EVENTS.ACTIVITY_CREATED] = this.socket.on(this.SOCKET_EVENTS.ACTIVITY_CREATED, this.onActivityCreatedSocketEvent.bind(this));
		//this.listeners[this.SOCKET_EVENTS.ACTIVITY_UPDATED] = this.socket.on(this.SOCKET_EVENTS.ACTIVITY_UPDATED, this.onActivityUpdatedSocketEvent.bind(this));
		//this.listeners[this.SOCKET_EVENTS.ACTIVITY_DELETED] = this.socket.on(this.SOCKET_EVENTS.ACTIVITY_DELETED, this.onActivityDeletedSocketEvent.bind(this));
		//this.listeners[this.SOCKET_EVENTS.ACTIVITY_VALIDATED] = this.socket.on(this.SOCKET_EVENTS.ACTIVITY_VALIDATED, this.onActivityValidatedSocketEvent.bind(this));
	}

	unbindSocketEventsListeners(){
		//this.socket.off(this.SOCKET_EVENTS.ACTIVITY_CREATED, this.listeners[this.SOCKET_EVENTS.ACTIVITY_CREATED]);
		//this.socket.off(this.SOCKET_EVENTS.ACTIVITY_UPDATED, this.listeners[this.SOCKET_EVENTS.ACTIVITY_UPDATED]);
		//this.socket.off(this.SOCKET_EVENTS.ACTIVITY_DELETED, this.listeners[this.SOCKET_EVENTS.ACTIVITY_DELETED]);
		//this.socket.off(this.SOCKET_EVENTS.ACTIVITY_VALIDATED, this.listeners[this.SOCKET_EVENTS.ACTIVITY_VALIDATED]);
	}

	$onInit(){
		this.bindSocketEventsListeners();
	}

	$onDestroy(){
		this.unbindSocketEventsListeners();
	}

	onActivityCreatedSocketEvent(id){
		console.debug('Activité créée', id);
		this.ActivityResource.find(id).then((activity) => {
			this.activities.push(activity)
		});
	}

	onActivityUpdatedSocketEvent(id){
		this.ActivityResource.find(id).then((activity) => {
			this.activities = this.activities.map((a) => {
				if(a.id == activity.id){
					return activity;
				}else{
					return a;
				}
			});
		});
	}

	onActivityDeletedSocketEvent(id){
		this.activities = this.activities.filter((a) => {
			return (a.id != id);
		});
	}

	onActivityValidatedSocketEvent(id){
		this.activities = this.activities.map((a) => {
			if(a.id == id){
				a.validated = 1;
			}
			return a;
		});
	}

//CGN+
	paginateFirst(){
		this.paginate(0);
	}

	paginate(num){
		this.page = parseInt(num);
	}

	paginatePrevious(){
		return (this.page-1 <= 0) ? this.page = 0 : --this.page;
	}

	paginateNext(){
		return (this.page+1 >= this.getPaginateCount()-1) ? this.page = this.getPaginateCount()-1 : ++this.page;
	}

	getPaginateCount(){
		return Math.ceil(this.getActivitiesFiltered().length / this.step);
	}

	seePage(index) {
		const plus = this.page + 10;
		const minus = this.page - 10;
		return index < 10 || (index > minus && index < plus);
	}
//CGN-

	getActivities(){
		console.log('this.filters', this.filters);
		let filtered = this.$filter('filterArrayByObject')(this.activities, this.filters);
		let ordered = this.$filter('orderBy')(filtered, 'date');
		let offset = this.page * this.step;
		let limit = (this.page * this.step) + this.step;
		let paginated = ordered.slice(offset, limit);
		let grouped = this.$filter('groupBy')(paginated, 'date');
//		let grouped = this.$filter('groupBy')(ordered, 'date');
		return grouped;
	}

	getActivitiesFiltered()
	{
		let filtered = this.$filter('filterArrayByObject')(this.activities, this.filters);
		return filtered;
	}

	canEditOrDelete(){
		return _.find(this.AuthSession.permissions, function(o) { return o == "*" || o == "activities.*";});
	}

	canEditOrDeleteOwnActivity(Activity){
		let user = this.AuthSession.user;
		if(Activity.contactEmployeeId == user.id || this.canEditOrDelete()){
			return true;
		}
	}


	canValidate(){
		return this.canEditOrDelete();
	}

	delete(Activity)
	{
		var a = Activity;
		let IConfirmModal = this.$modal.open({
			templateUrl:'/views/activity/activity-delete-modal.html',
			windowClass:'activity-modal activity-delete',
			resolve: {
				activity:function(){
					return a;
				}
			},
			controller:function($scope, activity){
				$scope.activity = activity;

				$scope.ok = function () {
					$scope.$close($scope.activity);
				};

				$scope.cancel = function () {
					$scope.$dismiss();
				};
			}
		});

		IConfirmModal.result.then((Activity) => {
			var id = Activity.id;
			Activity.destroy().then(() => {
				this.activities = this.activities.filter((activity) => activity.id != id)
			}, (errors) => {
				this.$modal.open({
					templateUrl:'/views/activity/activity-delete-modal-error.html',
					windowClass:'activity-modal activity-delete',
					resolve: {
						activity:function(){
							return a;
						}
					},
					controller:function($scope, activity){
						$scope.activity = activity;

						$scope.ok = function () {
							$scope.$dismiss();
						};

						$scope.cancel = function () {
							$scope.$dismiss();
						};
					}
				});
			});
		});
	}

	print(Activity){
		Activity.toPDF().then((binary) => {
			let blob, url;

			blob = new Blob([binary], {type:'application/pdf'});

			url = window.URL.createObjectURL(blob);

			let a = document.createElement("a");
			a.style = 'display:none';

			document.body.appendChild(a);

			a.href = url;
			a.download = Activity.title.toLowerCase() + '.pdf';
			a.click();

			window.URL.revokeObjectURL(url);
		});
	}

	validate(Activity)
	{
		Activity.validate().then(() => {
			Activity.validated = 1;
		});
	}

	reset(){
		this.filters = angular.extend({}, this.$state.params);
		this.paginateFirst();//CGN
	}

	export()
	{
		let ids = this.getActivitiesFiltered().map((a) => a.id);
		this.ActivityResource.exportByIdArray(ids).then((response) => {
			let time     = new Date().getTime();
			let headers  = response.headers();
			let content  = response.data;
			let filename = `activities_${time}.xls`;
			let blob     = new Blob([content], {type: headers['Content-Type']});

			return saveAs(blob, filename);
		});
	}
}
ActivitiesController.$inject = ['$state', '$filter', '$uibModal', /*'socket',*/ 'ActivityResource', 'ACTIVITY_TYPES', 'DISTRICTS', 'SOCKET_EVENTS', 'AuthSession'];

let ActivitiesComponent = {
	templateUrl: '/views/activity/activities.html',
	controller: ActivitiesController,
	bindings:{
		'activities':'='
	}
};

export default ActivitiesComponent;
