export default class Cache {
	constructor() {
		this.cache = {};
		this.timeouts = {};
	}
	set(key, value, ttl) {
		this.cache[key] = value;
		if (ttl) {
			// instead of using timeouts it could validate the ttl when `get` is called
			// so the amount of timeouts doesn't get out of control
			if (this.timeouts[key]) {
				clearTimeout(this.timeouts[key]);
			}
			this.timeouts[key] = setTimeout(() => {
				delete this.cache[key];
			}, ttl);
		}
	}
	get(key, ifUndefined, ttl) {
		var val = this.cache[key];
		if (!val && typeof ifUndefined === 'function') {
			this.set(key, ifUndefined(), ttl);
		}
		return this.cache[key];
	}
	has(key){
		return (key in this.cache);
	}
}