import SettingsResource from './SettingsResource';
import SettingsComponent from './SettingsComponent';
import SettingsMenuDirective from './SettingsMenuDirective';
import SettingsTableDirective from './SettingsTableDirective';

export default angular.module('settings.module', [])
.constant('SETTINGS_MENU_LINKS', [
	{
		icon: 'consult',
		svg:`<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		width="30px" height="60px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60; width:28px!important; margin:-5px 0 0 11px!important;"
		xml:space="preserve"><g id="Expanded"><g><g><path d="M32.5,60h-5c-2.067,0-3.75-1.682-3.75-3.75v-4.654c-1.6-0.463-3.14-1.103-4.602-1.908l-3.292,3.293
		c-1.417,1.418-3.886,1.414-5.304,0l-3.536-3.533c-0.709-0.709-1.099-1.651-1.099-2.652c0-1.002,0.39-1.943,1.099-2.65
		l3.294-3.295c-0.807-1.459-1.445-3-1.906-4.6H3.75C1.683,36.25,0,34.566,0,32.5v-5c0-1.003,0.39-1.944,1.097-2.651
		c0.708-0.708,1.65-1.099,2.653-1.099h4.655c0.462-1.598,1.1-3.139,1.907-4.6L7.02,15.856c-1.463-1.462-1.463-3.84,0-5.302
		l3.535-3.537c1.417-1.415,3.887-1.417,5.304,0l3.293,3.294c1.461-0.806,3-1.445,4.598-1.906V3.75C23.75,1.683,25.433,0,27.5,0h5
		c2.066,0,3.75,1.683,3.75,3.75v4.655c1.6,0.462,3.141,1.101,4.598,1.906l3.293-3.294c1.42-1.415,3.887-1.417,5.307,0l3.535,3.535
		c0.707,0.708,1.099,1.65,1.099,2.652c0,1-0.392,1.943-1.099,2.651l-3.293,3.293c0.807,1.46,1.447,3,1.908,4.601h4.652
		c2.068,0,3.75,1.682,3.75,3.75v5c0,1.002-0.391,1.944-1.098,2.651s-1.648,1.099-2.652,1.099h-4.654
		c-0.463,1.601-1.103,3.142-1.908,4.601l3.293,3.295c1.463,1.461,1.463,3.84,0,5.303l-3.533,3.535
		c-1.42,1.416-3.887,1.416-5.307,0l-3.291-3.295c-1.459,0.809-3,1.445-4.598,1.908v4.654C36.25,58.318,34.566,60,32.5,60z
		 M18.946,46.872c0.224,0,0.45,0.062,0.651,0.185c1.77,1.08,3.686,1.877,5.696,2.362c0.561,0.138,0.956,0.638,0.956,1.215v5.616
		c0,0.691,0.562,1.25,1.25,1.25h5c0.688,0,1.25-0.559,1.25-1.25v-5.616c0-0.577,0.396-1.077,0.957-1.215
		c2.007-0.485,3.924-1.278,5.692-2.362c0.496-0.302,1.129-0.226,1.537,0.182l3.974,3.977c0.469,0.467,1.299,0.469,1.769,0
		l3.535-3.537c0.488-0.488,0.488-1.279,0-1.769l-3.975-3.974c-0.408-0.406-0.484-1.043-0.182-1.537
		c1.082-1.768,1.875-3.683,2.36-5.692c0.134-0.563,0.638-0.959,1.215-0.959h5.616c0.334,0,0.648-0.131,0.885-0.366
		c0.236-0.232,0.365-0.545,0.365-0.881v-5c0-0.69-0.563-1.25-1.25-1.25h-5.616c-0.577,0-1.081-0.395-1.215-0.958
		c-0.485-2.008-1.278-3.925-2.36-5.695c-0.304-0.493-0.228-1.126,0.182-1.537l3.975-3.973c0.232-0.234,0.366-0.555,0.366-0.884
		s-0.134-0.651-0.366-0.884l-3.535-3.535c-0.475-0.471-1.297-0.471-1.771,0l-3.974,3.974c-0.406,0.41-1.041,0.486-1.537,0.182
		c-1.766-1.081-3.681-1.875-5.69-2.361c-0.562-0.134-0.957-0.635-0.957-1.212V3.75c0-0.69-0.562-1.25-1.25-1.25h-5
		c-0.688,0-1.25,0.56-1.25,1.25v5.616c0,0.578-0.396,1.079-0.956,1.215c-2.008,0.485-3.924,1.278-5.695,2.361
		c-0.494,0.301-1.127,0.227-1.535-0.182L14.09,8.786c-0.472-0.471-1.296-0.471-1.769,0l-3.533,3.535
		c-0.487,0.488-0.487,1.28,0,1.768l3.974,3.974c0.409,0.407,0.484,1.043,0.183,1.536c-1.083,1.771-1.876,3.687-2.361,5.694
		c-0.135,0.562-0.637,0.958-1.215,0.958H3.75c-0.334,0-0.649,0.13-0.885,0.366C2.63,26.85,2.5,27.164,2.5,27.499v5
		c0,0.688,0.562,1.25,1.25,1.25h5.616c0.578,0,1.08,0.394,1.215,0.958c0.486,2.01,1.28,3.926,2.361,5.693
		c0.301,0.492,0.227,1.125-0.182,1.535L8.786,45.91c-0.232,0.234-0.366,0.556-0.366,0.884c0,0.33,0.134,0.651,0.366,0.884
		l3.536,3.536c0.471,0.472,1.296,0.472,1.769,0l3.973-3.974C18.304,46.997,18.623,46.872,18.946,46.872z"/></g>
		<g><path d="M30,42.5c-6.893,0-12.5-5.607-12.5-12.5c0-6.893,5.607-12.5,12.5-12.5c6.893,0,12.5,5.607,12.5,12.5 C42.5,36.893,36.895,
		42.5,30,42.5z M30,20c-5.514,0-10,4.486-10,10c0,5.514,4.486,10,10,10c5.514,0,10-4.486,10-10 C40,24.486,35.516,20,30,20z"/></g>
		</g></g></svg>`,
		title: 'Configuration',
		state: {
			name: 'settings'
		}
	}
])
.provider('SettingsResource', SettingsResource)
.component('settings', SettingsComponent)
.directive('settingsMenu', SettingsMenuDirective)
.directive('settingsTable', SettingsTableDirective);