class AuthController {
	constructor($state, AuthService)
	{
		/*this.credentials = {
			'email': 'a.guyot@astorya.fr',
			'password': 'alex'
		};*/

		this.SUCCESS_LOGIN_REDIRECT_STATE = 'dashboard';
		this.SUCCESS_LOGOUT_REDIRECT_STATE = 'login';

		this.State                       = $state;
		this.AuthService                 = AuthService;
	}

	login(auth)
	{
		this.AuthService.login(auth)
		.then(() =>
		{
			this.redirectTo(this.SUCCESS_LOGIN_REDIRECT_STATE);
		});
	}

	logout(){
		this.AuthService.logout()
		.then(() => {
			this.redirectTo(this.SUCCESS_LOGOUT_REDIRECT_STATE);
		});
	}

	redirectTo(stateName, stateParams)
	{
		return this.State.go(stateName, stateParams);
	}
}
AuthController.$inject = ['$state', 'AuthService'];

let AuthComponent = {
	templateUrl: '/views/auth/login.html',
	controller: AuthController
};

export default AuthComponent;