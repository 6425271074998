import AuthSession from './AuthSession';
import AuthService from './AuthService';
import AuthEvents from './AuthEvents';
import AuthHttpInterceptor from './AuthHttpInterceptor';
import AuthComponent from './AuthComponent';

let AuthModule = angular.module('auth.module', [])
.service('AuthSession', AuthSession)
.service('AuthService', AuthService)
.service('AuthEvents', AuthEvents)
.service('AuthHttpInterceptor', AuthHttpInterceptor)
.component('login', AuthComponent)
.config(['$httpProvider', function($httpProvider){
	$httpProvider.interceptors.push('AuthHttpInterceptor');
}]);
export default AuthModule;