export default function(){
	return {
		restrict:'A',
		controller:function(){

		},
		link:function(){

		}
	}
}