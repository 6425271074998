class PageResource
{
	constructor(attributes = {})
	{
		this.id = attributes.id || null;
		this.parent_id = attributes.parent_id || null;
		this.lft = attributes.lft || null;
		this.rgt = attributes.rgt || null;
		this.depth = attributes.depth || null;
		this.title = attributes.title || '';
		this.slug = attributes.slug || '';
		this.body = attributes.body || '';
		this.isHome = attributes.isHome || 0;
		this.isAbstract = attributes.isAbstract || 0;
		this.created_at = attributes.created_at || null;
		this.updated_at = attributes.updated_at || null;
		this.children = attributes.children || [];
	}

	all(options = null)
	{
		var deferred = this.$q.defer();

		var url = `${this.REMOTE_BASE_URL}/pages`;
		if(options){
			url = `${url}?${this.$httpParamSerializerJQLike(options)}`;
		}

		this.$http.get(url)
		.success((rows) => deferred.resolve(rows.map((u) => new PageResource(u))))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	find(id)
	{
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/pages/${id}`)
		.success((row) => deferred.resolve(new PageResource(row)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	first(query)
	{
		return this.find(query).then((rows) => rows.shift());
	}

	save()
	{
		var request,
			deferred = this.$q.defer();

		if(this.id != null){
			request = this.$http.put(`${this.REMOTE_BASE_URL}/pages/${this.id}`, this);
		}else{
			request = this.$http.post(`${this.REMOTE_BASE_URL}/pages`, this);
		}

		request
		.success((u) => deferred.resolve(new PageResource(u)))
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}

	destroy()
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/pages/${this.id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
	destroyID(id)
	{
		var deferred = this.$q.defer();

		this.$http.delete(`${this.REMOTE_BASE_URL}/pages/${id}`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
	getFolders(){
		var deferred = this.$q.defer();

		this.$http.get(`${this.REMOTE_BASE_URL}/pages/folders`)
		.success(() => deferred.resolve())
		.error((err) => deferred.reject(err));

		return deferred.promise;
	}
}

export default function(){
	this.$get = ['$http', '$httpParamSerializer', '$httpParamSerializerJQLike', '$q', 'REMOTE_BASE_URL', '$timeout', function($http, $httpParamSerializer, $httpParamSerializerJQLike, $q, REMOTE_BASE_URL, $timeout){
		PageResource.prototype.$http = $http;
		PageResource.prototype.$httpParamSerializer = $httpParamSerializer;
		PageResource.prototype.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
		PageResource.prototype.$q = $q;
		PageResource.prototype.$timeout = $timeout;
		PageResource.prototype.REMOTE_BASE_URL = REMOTE_BASE_URL;
		return PageResource;
	}];
}