class MenuDirectiveController
{
	constructor($state)
	{
		this.$state = $state;
		this.showSubTitle = false;
		this.showTitle = false;
		if(this.$state.current.name == 'dashboard'){
			this.showTitle = true;
		}else{
			this.showSubTitle = true;
			this.subTitle = this.changeTitle(this.$state.current);
		}

	}

	menuItemClicked(item){
		if(this.onMenuItemClicked() || true){
			this.$state.go(item.state.name, item.state.params);//CGN
		}
	}

	menuItemHovered(item){
		if(this.onMenuItemHovered() || true){

		}
	}

	changeTitle(state){
		switch (state.name){
			case 'activities.validate':
				return 'Liste des activités à valider';
			case "activities.list":
				return 'Liste des activités';
			case "activities.create":
				return 'Ajouter une activité';
			case "activities.edit":
				return "Edition d'une activité";
			case "activities.archived":
				return "Liste des activités archivées";
			case "activities.validate.edit":
				return "Edition d'une activité non-validé";
			case "pages":
				return "Liste des pages";
			case "pages.create":
				return "Ajouter une page";
			case "pages.edit":
				return "Edition d'une page";
			case "actualites":
				return "Liste des actualités";
			case "actualites.create":
				return "Ajouter une actualité";
			case "actualites.edit":
				return "Edition d'une actualité";
			case "members":
				return "Liste des contacts";
			case "members.create":
				return "Ajouter un contact";
			case "members.edit":
				return "Edition d'un contact";
			case "members.deactivited":
				return "Liste des contacts désactivés";
			case "newsletters":
				return "Liste des newsletter";
			case "newsletters.create":
				return "Ajouter une newsletter";
			case "newsletters.edit":
				return "Edition d'une newsletter";
			case "settings":
				return "Configuration";
			case "history":
				return "Historique";
			case "publications":
				return "Liste des publications";
			case "publications.create":
				return "Ajouter une publication";
			case "districts":
				return "Liste des quartiers";
		}

	}
}
MenuDirectiveController.$inject = ['$state'];

function MenuDirective(){
	return {
		restrict:'E',
		scope:{
			title: '@',
			links:'=',
			onMenuItemClicked: '&',
			onMenuItemHovered: '&',
			subTitle :'@',
			showTitle : '@',
			showSubTitle : '@'
		},
		templateUrl:'/views/directives/menu.html',
		controller:MenuDirectiveController,
		controllerAs:'$ctrl',
		bindToController:true
	}
}

export default MenuDirective;
